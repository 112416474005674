import styled from 'styled-components'

import { movimiento } from '../../styles/animations'
import {
 pinkAuricula,
 blackAuricula,
 whiteAuricula,
 yellowAuricula,
 greyAuricula 

} from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  
  }

  @media (max-width: 600px) {
  display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
  width: 100%;
  height: 100vh;
  background-color: black;
  position:absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  animation: ${movimiento} .75s linear;
    
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div:nth-child(1) {
      margin-top: 12%;
      margin-left: 80%;
      width: 30px;
      height: 3px;
      position: relative;
      border-radius: 10px;

      > div:before,
        div:after {
        content: '';
        position: absolute;
        /* top: 30px; */
        width: 30px;
        height: 3px;
        background-color:${greyAuricula};
        border-radius: 10px;
      }

      > div:before {
        -webkit-transform: rotate(50deg);
        -moz-transform: rotate(50deg);
        transform: rotate(50deg);
        transform: rotate(50deg);
        /* left: -9px; */
      } 
      
      > div:after {
        -webkit-transform: rotate(-50deg);
        -moz-transform: rotate(-50deg);
        transform: rotate(-50deg);
        right: .8px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30%;
      
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4),
        div:nth-child(5) {
        padding-bottom: 20%;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color: ${whiteAuricula}; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;

          &:hover {
            color:${greyAuricula};
            background-size: 100% 2px;  
          }

          &:before {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${greyAuricula};
            position: absolute;
            top: 120%;
            left: 0;
            transition: all 0.5s;
          }

          &:hover:before {
            width: 50%;
            transform: translateX(100%);
          }

          &:after {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${greyAuricula};
            position: absolute;
            top: 120%;
            right: 0;
            transition: all 0.5s;
          }

          &:hover:after {
            width: 50%;
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}

`

export const Nav = styled.nav `
  display: flex;
  padding-bottom: 5%;
  background:black;
  
  @media (min-width:600px) and (max-width: 1024px) {

  }

  @media (max-width: 600px) {
    padding-bottom: 10%;   
  }
`

export const NavLogo = styled.section `
  margin : 5% 0 0 5%;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
    }
  }

  @media (max-width: 600px) {
    margin: 10% 0 0 10%;
    
    img {
    }
  }
`

export const NavButton = styled.div `
  display: flex;
  margin: 5% 0% 0% 60%;

  div {
    padding-right:15%;
    
    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: ${whiteAuricula};
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative; 
    }

    > a:hover {
      color: ${pinkAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${pinkAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${pinkAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }

  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 50%;

    div{

      > a {
       font-size: 2vw;
      }

      > a:hover {
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;
    margin: 5% 0% 0% 10%;

    div{

      > a {
      }

      > a:hover { 
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }  
    }
  }
`

export const MenuHamburguer = styled.div `
  display: none;
  
  
  &:hover{
    > div:nth-child(1) {
      
    }
  }

  &:hover{
    > div:nth-child(2) {
     
    }
  }

  &:hover{
    > div:nth-child(3) {
     
    }
  }

  > div:nth-child(1) {
   
  }

  > div:nth-child(2) {
    
  }

  > div:nth-child(3) {
   
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
      }
    }

    &:hover{
      > div:nth-child(2) {
      }
    }

    &:hover{
      > div:nth-child(3) {
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 11%;
    padding-left: 62.5%;
    cursor: pointer;
    

    &:hover{
      > div:nth-child(1) {
        background-color:${greyAuricula}
      }
    }

    &:hover{
      > div:nth-child(2) {
        background-color:${greyAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }
    }

    &:hover{  
      > div:nth-child(3) {
        background-color:${greyAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div {
      margin-bottom: 15%;
      width: 25px;
      height: 2px;
      background-color: white;
    }
  }
`



export const Video = styled.section `
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6% 0 6% 0;
  background: black;

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: none;
  }
 `

export const VideoIpad = styled.section `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
    display: inline-block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }


  @media (max-width: 600px) {
    display: none;
  }

`

export const VideoMobile = styled.section `
  display:none;

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }


  @media (max-width: 600px) {
    display:inline-block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }
`

export const Video1 = styled.section `
   display: flex;
  justify-content: center;
  width: 100%;
  padding: 6% 0 6% 0;
  background: black;

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: none;
  }

`

export const VideoIpad1 = styled.section `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
    display: inline-block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }


  @media (max-width: 600px) {
    display: none;
  }

`

export const VideoMobile1 = styled.section `
  display:none;

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }


  @media (max-width: 600px) {
    display:inline-block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }
`


export const Quote = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  background:black;
  padding-bottom: 8%;

  > div:nth-child(1) {
    width: 7%;
    height: 3px;
    margin: 8% 0 2% 0;
    background-color: ${greyAuricula};
  }

  > p {
    width: 30%;
    text-align:center;
    padding-bottom: 3%;
    font-size: 2vw;
    font-family:'NotoSansBold';
    color: ${whiteAuricula};
  }

  > div:nth-child(3) {
    display: flex;
    width: 8%;
    background-color: transparent;
    cursor: pointer;
    transition:all linear 1s;

    &:hover {
      background: ${greyAuricula};
      transition: all 1s;
    }

    &:hover {

    > div:nth-child(1) > a {
      color: ${blackAuricula};  
      transition: all 1s;
    }

    > div:nth-child(2) {
      border-left: 2.2em solid ${blackAuricula};
      transition: all 1s;
      
    } 
  }

  > div {
      display: flex;
      flex-direction: column;

      > a:nth-child(1) {
        width: 90%;
        color: ${whiteAuricula};
        font-size: 3vw;
        font-family:'BebasNeue' ;
        font-weight: bold;
        text-decoration: none;
        letter-spacing: 1px;
        line-height: 85%;
        margin-top: 3%;
        margin-left: 1%;
       
      }

    } 

    > div:nth-child(2) {
      margin-top: 28%;
      width: 0; 
      height: 0;
      border-left: 2.2em solid white;
      border-top: 1.5em solid transparent;
      border-bottom: 1.5em solid transparent;
      transition:all linear .5s;
    }
 }

 @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {
      
    }

    > p {
     
    }

    > div:nth-child(3) {
      

      &:hover {
        
      }

      &:hover {

      > div:nth-child(1) > a {
        
      }

      > div:nth-child(2) {
       
        
      } 
    }

    > div {
        

        > a:nth-child(1) {
          
        }

      } 

      > div:nth-child(2) {
        
      }
    }
 }

 @media (max-width: 600px) {
    padding-bottom: 15%;

    > div:nth-child(1) {
      width: 40%;
      margin: 15% 0 10% 0;
      background-color: ${greyAuricula};
    }

    > p {
      width: 60%;
      padding-bottom: 10%;
      font-size: 5vw;
    }

    > div:nth-child(3) {
      display: flex;
      width: 22%;
      background-color: transparent;
      cursor: pointer;
      transition:all linear 1s;

      &:hover {
        background: ${greyAuricula};
        
      }

      &:hover {

      > div:nth-child(1) > a {
        
      }

      > div:nth-child(2) {
        border-left: 1.8em solid ${blackAuricula};
        transition: all 1s;
        
      } 
    }

    > div {
      
        > a:nth-child(1) {
          width: 90%;
          color: ${whiteAuricula};
          font-size: 8vw;
          line-height: 85%;
          margin-top: 5%;
          margin-left: 1%;
          
        }

      } 

      > div:nth-child(2) {
        margin-top: 28%;
        width: 0; 
        height: 0;
        border-left: 1.8em solid white;
        border-top: 1em solid transparent;
        border-bottom: 1em solid transparent;
        transition:all linear .5s;
      }
    }
 }

`    
    
export const ContacUs = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${greyAuricula};
  padding: 4% 0;

 

  > a:nth-child(1) {
    font-size: 3vw;
    font-family: 'BebasNeue';
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(2) {
    font-size: 1.5vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(3) {
    margin-top:.8%;
    font-size: 1.1vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  @media (min-width:600px) and (max-width: 1024px) {
   

    > a:nth-child(1) {
      font-size: 4vw;
    }

    > a:nth-child(2) {
      font-size: 2vw;     
    }
    
    > a:nth-child(3) {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    padding: 10%; 

    > a:nth-child(1) {
      font-size: 8vw;
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
    }

    > a:nth-child(3) {
      font-size: 3.2vw;
    }
  }
`