import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { GlobalStyle } from './styles/globalStyles'

import Home from './components/home/home'
import WebpageAndProgramming from './components/webPageAndProgramming/webPageAndProgramming'
import InternalCommunication from './components/inetrnalCommunication/internalCommunication'
import ContactUs from './components/contactUs/contactUs'
import Services from './components/services/services'
import DesingAndAnimation from './components/desingAndAnimation/desingAndAnimation'
import Reel from './components/reel/reel'
import Test from './components/test/test'
import NoticeOfPrivacy from './components/noticeOfPrivacy/noticeOfPrivacy';




function App ( ) {

  return (
    <>
      <GlobalStyle/>
      <BrowserRouter>
        <Switch>
          <Route exact path = '/' component = {Home} />
          <Route exact path = '/paginas-web-y-programacion' component = {WebpageAndProgramming}/>
          <Route exact path = '/comunicacion-interna' component = {InternalCommunication}/>
          <Route exact path = '/contactanos' component = {ContactUs}/>
          <Route exact path = '/servicios' component = {Services}/>
          <Route exact path ='/diseño-y-animacion' component = {DesingAndAnimation}/>
          <Route exact path = '/reel' component = {Reel}/>
          <Route exact path = '/test/:valorquecambia' component={Test}/>
          <Route exact path = '/aviso-de-privacidad' component={NoticeOfPrivacy}/>
        </Switch> 
      </BrowserRouter>
    </>
  );
}

export default App;




