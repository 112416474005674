import React, {useState}  from 'react'
  
import {
  Nav,
  NavLogo,
  Navbuttons,
  NoticeTitle,
  MenuHamburguer,
  ModalHamburguer,
  NoticeTitleMobile,
  NoticeOfPrivacyCont,
  Identity,
  PersonalData,
  Footer,
  SensibleData,
  Traitment,
  Negative,
  Solicitud,
  Revocacion,
  Divulgacion,
  Cookies,
  Transferencia,
  Cambios
} from './noticeOfPrivacy.styles'


export default function NoticeOfPrivacy () {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)
 
  const showModalHamburguer = () => {
    setModalHamburguer(true)
  }

  const closeModalHamburguer = () => {
    setModalHamburguer(false)
  }


  return (
    <>
      <ModalHamburguer showModalHamburguer={modalHamburguer}>
        <div>
          <div>
            <div onClick={closeModalHamburguer}></div>
          </div>
          <div>
            <div>
              <a href="/">HOME</a>
            </div>
            <div>
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </div>
        </div>
      </ModalHamburguer>
      <div>
        <Nav>
          <NavLogo >
            <a href="/">
                <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_amarillo.png" alt="logotipo de auricula" />
            </a>
          </NavLogo>
          <NoticeTitle>
            <h2>AVISO DE PRIVACIDAD</h2>
          </NoticeTitle>
         <Navbuttons>
            <div>
              <a href="/">HOME</a>
            </div>
            <div >
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </Navbuttons>
          <MenuHamburguer onClick={showModalHamburguer}>
            <div></div>
            <div></div>
            <div></div>
          </MenuHamburguer>
          <NoticeTitleMobile>
            <h4>AVISO DE PRIVACIDAD</h4>
          </NoticeTitleMobile >
        </Nav>
        <NoticeOfPrivacyCont>
          <p>
           El presente documento constituye el Aviso de Privacidad para efectos
           de lo dispuesto en La Ley Federal de Protección de Datos Personales 
           en Posesión de los Particulares (en adelante “La Ley”), su Reglamento, 
           así como los Lineamientos del Aviso de Privacidad (en adelante 
           “Lineamientos”), a efecto de garantizar el Derecho a la Autodeterminación
           Informativa, privacidad, respeto y obligaciones que tenemos con relación 
           al uso adecuado de la información de aquellas personas que nos proporcionan 
           datos personales.
          </p>
          <Identity>
            <h4>1. IDENTIDAD Y DOMICILIO. </h4>
            <p>
              <span>AURÍCULA</span> con domicilio de 
              operaciones en <span>Cda de Calle 27, Edificio 21, interior 901, Col. Colina del Sur, Alcaldía. 
              Álvaro Obregón, C.P. 01430</span> , será el responsable del 
              tratamiento de sus datos personales de conformidad con lo 
              establecido en la “La Ley”.
            </p>
          </Identity>
          <PersonalData>
            <h4>2. DATOS PERSONALES QUE SERAN SOMETIDOS A TRATAMIENTO. </h4>
            <p>
              Los Datos Personales que recabamos de usted con los fines descritos 
              en el numeral 4 del presente Aviso de Privacidad, son 
              recabados de manera personal cuando nos los proporciona a través 
              de nuestras oficinas, de manera directa cuando ingresa sus datos a través 
              de nuestro sitio web, vía correo electrónico o a través de nuestro centro 
              de atención telefónica; y de manera indirecta cuando otras personas 
              físicas o morales que pueden ser nuestros clientes o 
              proveedores nos transfieren sus datos y cuando obtenemos 
              información a través de las fuentes de acceso público 
              permitidas por la “Ley”.
            </p>
            <p>
              Los datos personales de Identificación que recabamos son los siguientes:
            </p>
            <ul>
              <li>Nombre completo.</li>
              <li>Edad y/o fecha de nacimiento.</li>
              <li>Domicilio</li>
              <li>Correo electrónico</li>
              <li>Teléfonos de contacto</li>
              <li>Instagram / Facebook (redes sociales para concursos y/o promociones)</li>
              <li>
                Referencias y experiencia laborales, así como currículo profesional 
                (únicamente en el caso de candidatos a vacantes), los cuales 
                mantendremos solamente durante 6 meses a partir de la fecha 
                en que nos los proporcionó, después de ese plazo procederemos 
                a eliminar su información si no se llega a ninguna transacción 
                comercial, prestación de servicios o vínculo laboral; no obstante 
                lo anterior, durante dicho plazo sus datos podrán ser utilizados 
                para cualquier proceso de reclutamiento y selección de personal afín.
              </li>
            </ul>
          </PersonalData>
          <SensibleData>
            <h4>3. DATOS PERSONALES SENSIBLES. </h4>
            <p>
              <span>AURÍCULA NO RECABA DATOS PERSONALES DE CARÁCTER SENSIBLE</span>, solo recaba 
              aquellos datos que son necesarios para la relación comercial 
              o jurídica.
            </p>
          </SensibleData>
          <Traitment>
            <h4>4. DEL TRATAMIENTO Y/O RESGUARDO</h4>
            <p>
              <span>AURÍCULA</span> hace de su conocimiento que los Datos Personales 
              proporcionados voluntariamente, son recabados con 2 
              finalidades:
            </p>
            <p>
              a) PRIMARIAS. Finalidades que dan origen y son 
              necesarias para la existencia y cumplimiento de la obligación jurídica, 
              es decir, datos personales indispensables para brindar 
              nuestros servicios tales como:
            </p>
            <ul>
              <li>
                Registrarlo como Participante en los concursos y/o promociones que ofrecemos o 
                que ofrecen nuestros clientes.
              </li>
              <li>
                Identificarlo como ganador de nuestros concursos y/o 
                promociones o de nuestros clientes y poder otorgarle el premio correspondiente.
              </li>
              <li>Registrarse como suscriptor en su caso de nuestro sitio web.</li>
              <li>Ponerse en contacto con nosotros y enviarnos comentarios.</li>
              <li>
                Ofrecerle propuestas de trabajo o de prestación de servicios con base en 
                la información que nos proporcione de manera ocasional o para aplicar a vacantes específicas propias o de nuestros clientes.
              </li>
              <li>
                Dar seguimiento a los procesos de reclutamiento y selección de 
                personal correspondientes.
              </li>
            </ul>
            <p>
              b) SECUNDARIAS. Finalidades distintas y que no dan origen a la existencia 
              de la obligación jurídica, sin embargo son de gran importancia y 
              utilidad para brindarle nuestros servicios, que podrán ser:
            </p>
            <ul>
              <li>Evaluación y seguimiento de servicios prestados a nuestros clientes.</li>
              <li>
                Ofrecerle nuestros servicios, productos, concursos y promociones, 
                ya sean propias o de nuestros clientes, a través de correo electrónico o vía telefónica.
              </li>
              <p>
                
                
              </p>
              <li>
                Enviarle comunicados e informativas acerca de nuevos 
                servicios, concursos y/o promociones que  ofrecemos o que ofrecen nuestros clientes.
              </li>
            </ul>
          </Traitment>
          <Negative>
            <h4>5. NEGATIVA AL TRATAMIENTO DE SUS DATOS PERSONALES CON FINALIDADES SECUNDARIAS.</h4>
            <p>
              Cuando obtengamos sus Datos Personales de manera indirecta, usted 
              contará con un plazo de 5 (cinco) días hábiles contados a partir de la 
              fecha que fueron otorgados para manifestar su negativa al tratamiento de sus Datos 
              Personales para las finalidades secundarias señaladas en el numeral 4 (cuatro) inciso b), 
              del presente Aviso de Privacidad, para ello es necesario nos haga llegar una solicitud que 
              deberá contener los requisitos señalados en el numeral 7 (siete) del presente Aviso de Privacidad, 
              la cual deberá ser enviada a la dirección electrónica <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">corazon@auricula.mx</a>  haciendo mención 
              de los Datos y finalidades secundarias a las que desea negarse a su tratamiento. En un plazo 
              no mayor a 10 (diez) días hábiles contados a partir de la fecha de recepción de la solicitud, 
              haremos efectiva la negativa, notificándole al correo electrónico que usted haya señalado. <br />
              En caso de no manifestar su negativa al tratamiento de Datos Personales con las finalidades 
              secundarias anteriormente descritas en el plazo de 5 (cinco) días hábiles, se le tendrá por 
              consentido y aceptado el uso y tratamiento de sus Datos Personales para dichas finalidades; 
              sin embargo, esto no será impedimento para que usted en todo momento pueda ejercitar sus 
              Derechos ARCO que se describen en el numeral 6 del presente aviso de privacidad.
            </p>
          </Negative>
          <Solicitud>
            <h4>6. SOLICITUD ARCO.</h4>
            <p>
              Es importante informarle que usted tiene en todo momento el derecho al Acceso, Rectificación y 
              Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el 
              consentimiento que para dicho fin nos haya otorgado. Usted podrá ejercer estos derechos, por sí 
              mismo o a través de su representante legal dirigiendo una solicitud a la siguiente dirección de correo 
              electrónico <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">corazon@auricula.mx</a>, que contenga al menos la siguiente información:     
            </p>
            <ul>
              <li>
                La descripción de manera clara y precisa de los datos personales respecto de los 
                cuales busca ejercer sus derechos ARCO, así como el derecho o derechos que desea ejercer, lo cual 
                podrá hacer en el texto del correo electrónico o en un documento adjunto escaneado y debidamente 
                firmado al final del mismo y rubricado al calce de cada una de las hojas, o bien, firmados de 
                manera electrónica.
              </li>
              <li>
                Señalar expresamente su deseo de recibir nuestra respectiva contestación a su 
                petición a través de correo electrónico, indicando la dirección de correo electrónico que corresponda.
              </li>
              <li>
                Copia de identificación oficial vigente del Titular y/o de su representante legal. 
                Los documentos podrán ser escaneados y adjuntados al correo electrónico para verificar su veracidad.
              </li>
            </ul>
            <p>
              Una vez que su solicitud para ejercer sus derechos se encuentre a nuestra disposición, independientemente
              de la forma en que la recibamos conforme a lo anterior, se emitirá la respectiva contestación en un plazo 
              no mayor a 20 días hábiles a partir de su recepción; la cual haremos de su conocimiento a través del medio 
              de contacto que nos solicite. Una vez que usted o su representante reciba nuestra contestación, tendrá un 
              plazo de 20 (veinte) días hábiles para emitir su contestación. En caso de estar inconforme podrá contactarnos 
              de manera inmediata a través de los medios señalados, por lo que nos pondremos a su entera disposición de 
              inmediato para abordar sus inquietudes, independientemente de hacer valer ante la autoridad competente los 
              derechos que le correspondan. Si resulta procedente la solicitud de acceso, rectificación, cancelación u 
              oposición, se hará efectiva la misma dentro de los 15 (quince) días hábiles siguientes a la fecha en que se 
              comunique la respuesta. En caso de no responder nuestra contestación en el plazo señalado, <span>AURÍCULA</span> entenderá 
              de buena fe que está conforme con nuestra respuesta. Si su solicitud es sobre el ejercicio del derecho de 
              Acceso, <span>AURÍCULA</span>  pondrá su información o datos personales a su disposición a través de copias simples y/o documentos 
              electrónicos.
              </p>
              <p>
              En caso de omitir o enviar información errónea en su solicitud conforme a los requerimientos estipulados 
              en los párrafos anteriores, se le dará aviso de dicho error u omisión dentro de los 5 (cinco) días hábiles 
              siguientes a la solicitud, vía correo electrónico, y usted contará con un plazo de 10 (diez) días hábiles 
              para atender el requerimiento, de lo contrario su solicitud se tendrá como no interpuesta.
            </p>
          </Solicitud>
          <Revocacion>
            <h4>7. REVOCACIÓN DEL CONSENTIMIENTO AL TRATAMIENTO DE SUS DATOS PERSONALES. </h4>
            <p>
              En todo momento usted podrá revocar su consentimiento al tratamiento de sus Datos Personales, para ello es
              necesario nos haga llegar un correo electrónico a la siguiente dirección electrónica: <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">corazon@auricula.mx</a>,
              la cual deberá contener los requisitos señalados en el numeral 6 (seis) del presente Aviso de Privacidad, 
              haciendo un señalamiento de los Datos Personales a los cuales desea revocar su consentimiento. En un plazo no 
              mayor a 20 (veinte) días hábiles contados a partir de la recepción de su correo, haremos la revocación al 
              trato de los datos solicitados.
            </p>
          </Revocacion>
          <Divulgacion>
            <h4>8. USO O DIVULGACIÓN DE LOS DATOS PERSONALES.</h4>
            <p>
              <span>AURÍCULA</span> pone a su disposición la siguiente dirección de correo electrónico <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">corazon@auricula.mx</a>, para que 
              usted pueda manifestar en todo momento su negativa a seguir recibiendo comunicados o informativas de nuestros 
              productos servicios, concursos o promociones. <br />
              Es necesario hacerle saber que para restringir, limitar o controlar el tratamiento de sus datos 
              personales <span>AURÍCULA</span> cuenta con medidas de seguridad administrativas, físicas y técnicas, además de establecer 
              políticas y programas internos de privacidad para evitar la divulgación de sus datos personales, implementando 
              diversos controles de seguridad. Los datos personales de los cuales es titular serán tratados de forma estrictamente 
              confidencial, por lo que la obtención, tratamiento, transferencia y ejercicio de los derechos derivados de dichos 
              datos personales, es mediante el uso adecuado, legítimo y licito, salvaguardando permanentemente los principios de 
              licitud, consentimiento, calidad, información, proporcionalidad y responsabilidad.
            </p>
          </Divulgacion>
          <Cookies>
            <h4>9. “USO DE “WEB BEACONS” Y COOKIES”.</h4>
            <p>
            Algunas partes del sitio web <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">www.auricula.mx</a>  puede
            utilizar "Cookies" para simplificar la navegación. Son pequeños archivos de texto que se almacenan en su disco 
            duro que nos ayudan a ofrecer una experiencia más personalizada en nuestro sitio web. Por ejemplo, una cookie se 
            puede utilizar para almacenar información de registro en un área del sitio para que el usuario no tenga que volver a 
            incluirla en sus siguientes visitas a dicha área. Es política de <span>AURÍCULA</span> utilizar cookies para simplificar la 
            navegación en nuestros sitios web y facilitar los procedimientos de envío de información. Tanto las "cookies" 
            como los "web beacons" almacenan información personal tal como nombres o direcciones electrónicas. 
            La mayoría de navegadores permiten a los usuarios rechazar las "cookies". Cabe mencionar que en circunstancias 
            específicas, el acceso puede ser denegado en ciertas partes de nuestro sitio a aquellos visitantes cuyos navegadores 
            no permitan el uso de "cookies".
            </p>
          </Cookies>
          <Transferencia>
            <h4>10. TRANSFERENCIA DE DATOS PERSONALES </h4>
            <p>
            <span>AURÍCULA</span> de conformidad con el artículo 37 fracciones III, IV, V, VI y VII de la Ley Federal de Protección
            de Datos Personales en Posesión de los Particulares, podrá transferir sus datos a terceros denominados como “terceros 
            autorizados” cuando sea estrictamente necesario y conforme a las siguientes finalidades:
            </p>
            <h5>EN EL CASO DE FINALIDADES PRIMARIAS</h5>
            <ul>
              <li>
                Se podrán transferir a empresas controladoras, subsidiarias, filiales, afiliadas y empresas del 
                mismo grupo corporativo al que pertenece <span>AURÍCULA</span>; así como a nuestros clientes con los que organicemos o 
                desarrollemos concursos y/o promociones, para poder dar cumplimiento a las finalidades establecidas en el apartado de 
                finalidades del tratamiento y/o resguardo del presente aviso de privacidad.  
              </li>
              <li>
                En el caso de ofrecimiento de vacantes, se podrán transferir sus datos a empresas controladoras,
                subsidiarias,  filiales, afiliadas y empresas del mismo grupo corporativo al que pertenece <span> AURÍCULA</span> para tomar 
                en cuenta su perfil para cubrir alguna vacante disponible, y a nuestros proveedores de servicios de administración de personal 
                en beneficio de usted, con base en los contratos que tenemos celebrados con estos, ante una posible contratación de 
                resultar aplicable. 
              </li>
              <li>
                Se podrán transferir a autoridades competentes que lo hayan solicitado como parte de su proceso
                legal o en caso específico por mandato judicial, en estos casos le será informado previamente el tipo de información 
                que es requerida y los fines para los que ha sido solicitada. 
              </li>
            </ul>
            <h5>EN EL CASO DE FINALIDADES SECUNDARIAS</h5>
            <ul>
              <li>
                Empresas con actividades comerciales. Para prospección comercial y venta de productos o
                servicios.
              </li>
              <li>
                Empresas con actividades de Mercadotecnia y Publicidad. Con la finalidad de realizar 
                estudios, encuestas de mercado y difundir Publicidad y comunicados promocionales.
              </li>
              <li>
                Empresas con actividades Promocionales. Con la finalidad de hacer de su conocimiento
                promociones o concursos propios u ofrecidos por nuestros clientes.
              </li>
            </ul>
          </Transferencia>
          <Cambios>
            <h4>11. CAMBIOS AL AVISO DE PRIVACIDAD.</h4>
            <p>
              <span>AURÍCULA</span> al llevar a cabo alguna modificación, derivado de novedades o actualizaciones legislativas, 
              políticas internas y/o nuevos requerimientos, publicará o actualizará el presente Aviso de Privacidad, 
              por lo cual se le solicita que consulte de manera frecuente a fin de mantenerse informado el sitio <a href="mailto:corazon@auricula.mx?subject=Quiero contactarlos">www.auricula.mx</a>  sección 
              aviso de privacidad, con la finalidad de mantenerse actualizado y al tanto de los términos y condiciones vigentes,
              aun en caso que el titular desee hacer uso de sus derechos ARCO.
            </p>
          </Cambios>  
        </NoticeOfPrivacyCont>
        < Footer >
          <a href="/contactanos">CONTÁCTANOS</a>
          <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos' >corazon@auricula.mx</a>       
        </ Footer >
      </div>

    </>
  )
}