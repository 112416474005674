import React, {useState} from 'react'

import {
  ModalHamburguer,
  Nav,
  NavLogo,
  NavButton,
  MenuHamburguer,
  Video,
  VideoIpad,
  VideoMobile,
  Video1,
  VideoIpad1,
  VideoMobile1,
  Quote,
  ContacUs
} from './desingAndAnimation.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'

export default function WebPageAndProgramming (props) {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)

  const sendToQuotation = () => props.history.push('/cotizacion')

  const showModalHamburguer = () => setModalHamburguer(true)

  const closeModalHamburguer = () => setModalHamburguer(false)

  return ( 
    <>
      <ModalHamburguer showModalHamburguer={modalHamburguer}>
        <div>
          <div>
            <div onClick={closeModalHamburguer}></div>
          </div>
          <div>
            <div>
              <a href="/">HOME</a>
            </div>
            <div>
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </div>
        </div>
      </ModalHamburguer>
      <WhatsApp/>
      <Nav>
        <NavLogo>
          <a href="/">
            <figure>
              <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_gris.png" alt="" />
            </figure>
          </a>
        </NavLogo>
        <NavButton>
          <div>
            <a href="/">HOME</a>
          </div>
          <div>
            <a href="/servicios">SERVICIOS</a>
          </div>
          <div>
            <a href="/reel">REEL</a>
          </div>
          <div>
            <a href="http://www.blog.auricula.mx">BLOG</a> 
          </div>
          <div>
            <a href="/contactanos">CONTACTO</a>
          </div>
        </NavButton>
        <MenuHamburguer onClick={showModalHamburguer}>
          <div></div>
          <div></div>
          <div></div>
        </MenuHamburguer>
      </Nav>
      {/* <WhatsApp/> */}
      <Video>
        <video height="700px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+ANIMACION.mp4" type="video/mp4" />
        </video>
      </Video>
      <VideoIpad>
        <video height="500px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+ANIMACION.mp4" type="video/mp4" />
        </video>
      </VideoIpad>
      <VideoMobile>
        <video height="600px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+VERTICAL+ANIMACION.mp4" type="video/mp4" />
        </video>
      </VideoMobile>
      <Video1>
        <video height="700px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+DISEN%CC%83O_2+3.mp4" type="video/mp4" />
        </video>
      </Video1>
      <VideoIpad1>
        <video height="500px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+DISEN%CC%83O_2+3.mp4" type="video/mp4" />
        </video>
      </VideoIpad1>
      < VideoMobile1>
        <video height="600px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+VERTICAL+DISEN%CC%83O.mp4" type="video/mp4" />
        </video>
      </ VideoMobile1>
      <Quote>
        <div></div>
        <p>Agenda un acita.</p>
        <div onClick={sendToQuotation}>
          <div>
            <a href="/contactanos">DA CLIC AQUÍ</a>
          </div>   
          <div></div> 
        </div>
      </Quote>
      <ContacUs>
        <a href="/contactanos">CONTACTANOS</a>
        <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos'>corazon@auricula.mx</a>
        <a href="/aviso-de-privacidad">Aviso de privacidad</a>
      </ContacUs>
    </>
  )
}