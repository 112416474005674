import React, {useState} from 'react'

import {
  ModalHamburguer,
  Nav,
  NavLogo,
  NavButton,
  MenuHamburguer,
  PageWebInfo,
  Video,
  VideoIpad,
  VideoMobile,
  Quote,
  ContacUs
} from './webPageAndProgramming.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'


export default function WebPageAndProgramming (props) {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)
  const [test1, setTest1] = useState(false)
  // const [pruebas2, setPruebas2] = useState(false)
  

  const sendToQuotation = () => props.history.push('/cotizacion')

  const showModalHamburguer = () => setModalHamburguer(true)

  const closeModalHamburguer = () => setModalHamburguer(false)

  const mousePrueba = () =>  setTest1(!test1)

  // const prueba2 = (evento) => {
  //   console.log(evento)
  //   console.log('page x', evento.pageX)
  //   console.log('page y', evento.pageY)
  //   console.log('relatedTarget', evento.relatedTarget.attributeStyleMap.size)

  //   if(evento.pageX >= 400 ){
  //     console.log('Soy mayor a 400')
  //     setPruebas2('Mayor')

  //   }else {
  //     console.log('Soy menor a 400')
  //     setPruebas2('Menor')
  //   }
  // }
  
  return ( 
    <>
      <ModalHamburguer showModalHamburguer={modalHamburguer}>
        <div>
          <div>
            <div onClick={closeModalHamburguer}></div>
          </div>
          <div>
            <div>
              <a href="/">HOME</a>
            </div>
            <div>
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </div>
        </div>
      </ModalHamburguer>
      <WhatsApp/> 
      <Nav>
        <NavLogo>
          <a href="/">
            <figure>
              <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_rosa.png" alt="logo auricula" />
            </figure>
          </a>
        </NavLogo>
        <NavButton>
          <div>
            <a href="/">HOME</a>
          </div>
          <div>
            <a href="/servicios">SERVICIOS</a>
          </div>
          <div>
            <a href="/reel">REEL</a>
          </div>
          <div>
            <a href="http://www.blog.auricula.mx">BLOG</a> 
          </div>
          <div>
            <a href="/contactanos">CONTACTO</a>
          </div>
        </NavButton>
        <MenuHamburguer onClick={showModalHamburguer}>
          <div></div>
          <div></div>
          <div></div>
        </MenuHamburguer>
      </Nav>
      <PageWebInfo>
        <h2>PÁGINAS WEB </h2>
        <h2>& PROGRAMACIÓN</h2>
        <div></div>
        <p>
          En <span>Aurícula,</span> contamos con un equipo exclusivo para
          desarrollo de <span>páginas web a la medida.</span> Desde la
          funcionalidad, el diseño, la programación y complementos.
        </p>
        <p>
          No importa si eres una Pyme y buscas una landing page, o
          si quieres una tienda en línea o una página web, podemos
          realizar cualquier tipo de sitio <span>optimizado a tus necesidades.</span>
        </p>
      </PageWebInfo>
      <Video>
        <video height="700px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </Video>
      <VideoIpad>
        <video height="500px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </VideoIpad>
      <VideoMobile>
        <video height="600px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+VERTICAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </VideoMobile>
      <Quote >
      {/* MouseUp = { mousePrueba } cambiocolor = {test1}  */}
        <div></div>
        <p >Agenda una cita.</p>
        <div onClick={sendToQuotation}>
          <div>
            <a href="/contactanos">DA CLIC AQUÍ</a>
          </div>   
          <div></div> 
        </div>
      </Quote>
      <ContacUs>
      {/* onMouseOver={prueba2} changeColor = {pruebas2} */}
        <a href="/contactanos">CONTACTANOS</a>
        <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos'>corazon@auricula.mx</a>
        <a href="/aviso-de-privacidad">Aviso de privacidad</a>
      </ContacUs>
    </>
  )
}