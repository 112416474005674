import React from 'react';

import {
  WhatsappContainer
  
} from './whatsappHome.styles'

export default function WhatsApp () {

  return (
    <WhatsappContainer>
      <a href="https://wa.link/5nh9ne">
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/botonWhatsapp.png" alt="Botón de whatsapp" /> 
        </figure>
      </a>
    </WhatsappContainer>
  )
}
