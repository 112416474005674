import styled from "styled-components";

export const WhatsappContainer = styled.div `
  

  > a {
    display: flex;
    justify-content: flex-end;
    

    > figure {
      margin-top: 20%;
      height: 25%;
      position: fixed;
      z-index: 1000;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  

  @media (min-width:600px) and (max-width: 1024px) {
    > a {
   
      > figure {
        margin-top: 30%;
        height: 20%;

        > img {
        }
      }
    }
  }

  @media (max-width: 600px) {
    a {
      display: flex;
      justify-content: flex-end;

      > figure {
        margin-top: 60%;
        height: 20%;
        position: fixed;
        z-index: 1000;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`

