import styled from "styled-components"

import { movimiento } from '../../styles/animations'

import { 
  whiteAuricula,
  yellowAuricula,
  blackAuricula
} from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
    position: absolute;
    display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
    width: 100%;
    height: 100vh;
    background-color: black;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 1000;
    animation: ${movimiento} .75s linear;
    
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;

      > div:nth-child(1) {
        margin-top: 12%;
        margin-left: 80%;
        width: 30px;
        height: 3px;
        position: relative;
        border-radius: 10px;

        > div:before,
          div:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 3px;
          background-color: ${yellowAuricula};
          border-radius: 10px;
        }

        > div:before {
          -webkit-transform: rotate(50deg);
          -moz-transform: rotate(50deg);
          transform: rotate(50deg);
          /* left: -9px; */
        } 
        
        > div:after{
          -webkit-transform: rotate(-50deg);
          -moz-transform: rotate(-50deg);
          transform: rotate(-50deg);
          right: .8px;
        }
      }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30%;
      
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4),
        div:nth-child(5) {
        padding-bottom: 20%;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color:white; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;
        }

        > a:hover {
          color:${yellowAuricula};
          background-size: 100% 2px;  
        }

        > a:before {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          left: 0;
          transition: all 0.5s;
        }

        > a:hover:before {
          width: 50%;
          transform: translateX(100%);
        }

        > a:after {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          right: 0;
          transition: all 0.5s;
        }

        > a:hover:after {
          width: 50%;
          transform: translateX(-100%);
        }  
      }
    }
  }
}
`

export const ContUs = styled.section `
 

 

  @media (min-width:600px) and (max-width: 1024px) {
     
  }

  @media (max-width: 600px) {
    background: ${whiteAuricula};

    > div {
      
    }
}

`

export const Nav = styled.section `
  display: flex;
  background: black;
  padding-bottom: 2%;
  
  @media (min-width:600px) and (max-width: 1024px) {
     
  }

  @media (max-width: 600px) {
    padding-bottom: 10%;
  }
`


export const NavLogo = styled.section `
  margin : 5% 0 0 5% ;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
      
    }
  }

  @media (max-width: 600px) {
    margin : 10% 0 0 10% ;
    
    img {
      
    }
  }
`

export const Navbuttons = styled.section `
  display: flex;
  margin: 5% 0% 0% 60%;

    
  > div {
    padding-right:15%;

    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative;
      
    }

    > a:hover {
      color:${yellowAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }
  
  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 50%;

      
    div{


      > a {
       font-size: 2vw;
      }

      > a:hover {
       
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
       
      }

      > a:hover:after {
        
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;
    margin: 5% 0% 0% 10%;

    div{
      padding-right:15%;

      > a {
        font-size: 3vw;  
      }

      > a:hover {
        
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
        
      }

      > a:hover:after {
       
      }  
    }
  }

`

export const MenuHamburguer = styled.div `
  display: none;
  
  &:hover{
    > div:nth-child(1) {
    
    }
  }

  &:hover{
    > div:nth-child(2) {
      
    }
  }

  &:hover{
    > div:nth-child(3) {
     
    }
  }

  > div:nth-child(1) {
  
  }

  > div:nth-child(2) {
  
  }

  > div:nth-child(3) {
  
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
        
      }
    }

    &:hover{
      > div:nth-child(2) {
        
      }
    }

    &:hover{
      > div:nth-child(3) {
        
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 11%;
    padding-left: 62.5%;
    cursor: pointer;
  
    &:hover{
      > div:nth-child(1) {
        background-color:${yellowAuricula} ;
      }
    }

    &:hover{
      > div:nth-child(2) {
        background-color:${yellowAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }
    }

    &:hover{
      > div:nth-child(3) {
        background-color:${yellowAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div {
      margin-bottom: 15%;
      width: 25px;
      height: 2px;
      background-color: white;
    }
  }
`


// >>>>>>> Aqui empieza formulario >>>>>>>

export const FormCont = styled.section `
  display: flex;
  position: relative;

  @media (min-width:600px) and (max-width: 1024px) {
    
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column-reverse;
  }

  @media (min-width:1600px) and (max-width: 2560px) {
    
  }
`


export const FormContNotes = styled.div `
  width: 65%;
  padding-left: 15%;
  padding-top: 40%;
  padding-bottom: 5%;
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/atrium1.0/Img_chico.png");

  > h2 {
    margin-bottom: 3%;
    color: ${whiteAuricula};
    font-size: 2.5vw;
    font-family: 'BebasNeue';
    letter-spacing: 2px;
  }

  > div {
    display: flex;

    > div {
      margin-top: 1.3%;
      width: 0; 
      height: 0; 
      border-left: 6px solid ${yellowAuricula};
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
    }

    > p {
      margin-left: 1%;
      margin-bottom: 1.5%;
      width: 64%;
      color: ${whiteAuricula};
      font-family: 'NotoSansRegular';
      font-size: 1.1vw;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {

    > h2 {
   
    }

    > div {
     

      > div {

      }

      > p {
      
      }
    }
  }
  
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10%;
    padding-top: 135%;
    padding-bottom: 10%;
    background-size: cover;
    background-image: url("https://s3.amazonaws.com/atrium1.0/CEL_IMG_FORMULARIO.png");

    > h2 {
      margin-bottom: 3%;
      color: ${whiteAuricula};
      font-size: 7vw;
      font-family: 'BebasNeue';
      letter-spacing: 2px;
    }

    > div {
      display: flex;

      > div {
        margin-bottom: 7%;
        margin-top: 2%;
        width: 0; 
        height: 0; 
        border-left: 8px solid ${yellowAuricula};
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
      }

      > p {
        margin-left: 4%;
        margin-bottom: 5%;
        width: 70%;
        font-size: 4.5vw;
      }
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {


    > h2 {
      
    }

    > div {
      

      > div {
        margin-top: 1.2%;
        border-left: 11px solid ${yellowAuricula};
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
      }

      > p {
       
      }
    }
  }
    
`

export const FormContForm = styled.div `
  background-color: ${yellowAuricula};
  width: 35%;
  height: 100%;
  padding-bottom: 4%;
  margin-top: 5.5%;
  margin-left: -10%;
  box-shadow: 3px 8px 16px #00000017;
  
  > h2 {
    margin: 10% 0 2% 10%;
    width: 70%;
    color: ${blackAuricula};
    font-size: 2.2vw;
    font-family: 'BebasNeue';
    letter-spacing: 1px;
  }
  
  > div:nth-child(2) {
    margin-left: 10%;
    margin-bottom: 8%;
    width: 40%;
    height: 2px;
    background-color: ${blackAuricula};
  }

  > div:nth-child(3),
  > div:nth-child(4),
  > div:nth-child(5),
  > div:nth-child(6),
  > div:nth-child(7) {
    margin-left: 10%;

    > p {
      margin-bottom: 2.5%;
      color: ${blackAuricula};
      font-family: 'NotoSansRegular';
      font-size: 1.1vw;
    }

    > input {
      width: 90%;
      height: 30px;
      margin-bottom: 5%;
      padding-left: 2%;
      border: none;
      border-radius: 4px;
      box-shadow: 0.34px 0.94px 8px #BDB10069;
      outline: none;
      -webkit-appearance: none; 
     
    }
  }

  > div:nth-child(8) {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 2% auto;

    > div {
      display: flex;
      width: 50%;
    
    //**************CHECKBOX*********************//
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
    }

    .container input {
      position: absolute;
      opacity: 0;
      height: 0;
      width: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 15px;
      width: 15px;
      background-color: #eee;
    }

    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .container input:checked ~ .checkmark {
      background-color: ${blackAuricula};
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      left: 5px;
      top: 1.5px;
      width: 3px;
      height: 7px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    //**************CHECKBOX*********************//


    > p {
      margin-left: 3%;
      color: ${blackAuricula};
      font-family: 'NotoSansRegular';
      font-size: 0.8vw;
    }
  }

    > a {
      color: ${blackAuricula};
      font-family: 'NotoSansRegular';
      font-size: .8vw;
      font-weight: bold;
    }
  }

  > button:last-child {
    margin-top: 7%;
    margin-left: 10%;
    width: 20%;
    height: 40%;
    background-color: ${blackAuricula};
    border: none;
    cursor: pointer;
    box-shadow: 0.34px 0.94px 8px #00000069;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

        > p {
          margin-top: 2%;
          color: ${whiteAuricula};
          font-size: 2vw;
          font-family: 'BebasNeue';
          letter-spacing: 1px;
      }

      > div {
        width: 0; 
        height: 0; 
        border-left: 15px solid ${whiteAuricula};
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        margin-left: 3%;
      }
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {    
    height: 605px;
    margin-top: 2.5%;

    > h2 {
      margin: 10% 0 2% 10%;
      width: 72%;
    }
    
    > div:nth-child(2) {

    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5),
    > div:nth-child(6),
    > div:nth-child(7) {
      

      > p {
       
      }

      > input {
      
      }
    }

     

    > div:nth-child(8) {

      > div {
      
      //**************CHECKBOX*********************//
      .container {
      }

      .container input {
      }

      .checkmark {
      }

      .container:hover input ~ .checkmark {
      }

      .container input:checked ~ .checkmark {
      }

      .checkmark:after {
      }

      .container input:checked ~ .checkmark:after {
      }

      .container .checkmark:after {
      }
      //**************CHECKBOX*********************//


      > p {
      }
    }

      > a {
      }
    }

    > button:last-child {
      height: 7%;
      
      > div {

         > p {
          margin-top: 3%;
        }

      > div {
        margin-left: 3%;
        border-left: 10px solid ${whiteAuricula};
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
       }
      }
    }
  }
  
  @media (max-width: 600px) {
    background-color: ${yellowAuricula};
    width: 100%;
    padding-bottom: 850px;
    height: 0;
    margin-top: 0;
    margin-left: 0;
    
    > h2 {
      margin: 10% 0 2% 10%;
      width: 80%;
      font-size: 7vw;
    }
    
    > div:nth-child(2) {
      margin-left: 10%;
      margin-bottom: 8%;
      width: 46%;
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5),
    > div:nth-child(6),
    > div:nth-child(7) {
      margin-left: 10%;

      > p {
        margin-bottom: 2.5%;
        font-size: 4.5vw;
      }

      > input {
        font-size: 4vw;
        height: 40px;
      }
    }

    > div:nth-child(8) {
      display: flex;
      flex-direction: column;
      width: 80%;
      margin: 2% auto;

      > div {
        display: flex;
        width: 100%;   

      //**************CHECKBOX*********************//
        .container {      
        }

        .container input {
        }

        .checkmark {
          height: 30px;
          width: 30px;
        }

        .container:hover input ~ .checkmark {
        }

        .container input:checked ~ .checkmark {
        }

        .checkmark:after {
        }

        .container input:checked ~ .checkmark:after {
        }

        .container .checkmark:after {
          left: 9px;
          width: 8px;
          height: 17px;
        }
        //**************CHECKBOX*********************//

        > p {
          margin-left: 12%;
          margin-top: 3%;
          font-size: 4.5vw;
        }
      }

      > a {
        margin-top: 5%;
        font-size: 4.5vw;
        text-align: center;
      }
    }

    > button:last-child {
      margin-left: 33.33%;
      width: 30%;
      height: 50px;

      > div {
       

          > p {
            margin-top: 2%;
            font-size: 8vw;
            letter-spacing: 1px;
        }

        > div {
          width: 0; 
          height: 0; 
          border-left: 20px solid ${whiteAuricula};
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent; 
          margin-left: 3%;
        }
      }
    }
  }


  @media (min-width:1600px) and (max-width: 2560px) {
    background-color: ${yellowAuricula};
    width: 35%;
    height: 1380px;
    margin-top: 4%;
    margin-left: -10%;
    box-shadow: 3px 8px 16px #00000017;
    
    > h2 {
      margin: 10% 0 2% 10%;
      width: 68%;
    }
    
    > div:nth-child(2) {
 
    }

    > div:nth-child(3),
    > div:nth-child(4),
    > div:nth-child(5),
    > div:nth-child(6),
    > div:nth-child(7) {
      

      > p {
      
      }

      > input {
        height: 60px;
        font-size: 1.3vw;
      }
    }

    > div:nth-child(8) {
      

      > div {

        .container {
        }

        .container input {
        }

        .checkmark {
          height: 25px;
          width: 25px;
        }

        .container:hover input ~ .checkmark {
        }

        .container input:checked ~ .checkmark {
        }

        .checkmark:after {
        }

        .container input:checked ~ .checkmark:after {
        }

        .container .checkmark:after {
          left: 7px;
          top: 0.5px;
          width: 8px;
          height: 15px;
        }

        > p {
         
        }
      }

      > a {
       
      }
    }

    > button:last-child {
      height: 82px;
      width: 22%;
     

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        
       

        > p {
          color: ${whiteAuricula};
          font-size: 2vw;
          font-family: 'BebasNeue';
          letter-spacing: 1px;
      }

      > div {
        width: 0; 
        height: 0; 
        border-left: 23px solid ${whiteAuricula};
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent; 
        margin-left: 3%;
      }
    }
  }
}

`

export const Footer = styled.footer `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:${yellowAuricula};
  padding: 4% 0;

  > a:nth-child(1) {
    padding-top:4%;
    font-size: 3vw;
    font-family: 'BebasNeue' ;
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(2) {
    font-size: 1.5vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(3) {
    margin-top:.8%;
    font-size: 1.1vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  @media (min-width:600px) and (max-width: 1024px) {

    > a:nth-child(1) {
      font-size: 4vw;
    }

    > a:nth-child(2) {
      font-size: 2vw;
    }

    > a:nth-child(3) {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    padding: 10% ;

    > a:nth-child(1) {
      font-size: 8vw;
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
    }

    > a:nth-child(3) {
      font-size: 3.2vw;
    }
  }
`





