import React, {useState} from 'react'

import {
  ModalHamburguer,
  Nav,
  NavLogo,
  InterCommNavButton,
  MenuHamburguer,
  InterCommInfo,
  Cat,
  WeAreAtrium,
  OurClients,
  Tools,
  Footer,

} from './internalCommunication.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'

export default function InternalCommunication () {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)

  const showModalHamburguer = () => setModalHamburguer(true)

  const closeModalHamburguer = () => setModalHamburguer(false)

  return (
    <>
    <ModalHamburguer showModalHamburguer={modalHamburguer}>
      <div>
        <div>
          <div onClick={closeModalHamburguer}></div>
        </div>
        <div>
          <div>
            <a href="/">HOME</a>
          </div>
          <div>
            <a href="/servicios">SERVICIOS</a>
          </div>
          <div>
            <a href="/reel">REEL</a>
          </div>
          <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
          </div>
          <div>
            <a href="/contactanos">CONTACTO</a>
          </div>
        </div>
      </div>
    </ModalHamburguer>
    <WhatsApp/>
    <Nav>
      <NavLogo>
        <a href="/">
          <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_amarillo.png" alt="logo auricula" />
        </a>
      </NavLogo>       
      <InterCommNavButton>
        <div>
          <a href="/">HOME</a>
        </div>
        <div>
          <a href="/servicios">SERVICIOS</a>
        </div>
        <div>
          <a href="/reel">REEL</a>
        </div>
        <div>
          <a href="http://www.blog.auricula.mx">BLOG</a> 
        </div>
        <div>
          <a href="/contactanos">CONTACTO</a>
        </div>
      </InterCommNavButton>
      <MenuHamburguer onClick={showModalHamburguer}>
        <div></div>
        <div></div>
        <div></div>
      </MenuHamburguer>
    </Nav>
    <InterCommInfo>
      <div>
        <h2>COMUNICACIÓN INTERNA</h2>
        <div></div>
        <p>
          A través de la <span>comunicación interna</span>  es posible
          que tus colaboradores se <span>comprometan</span>, sean
          más  <span>productivos</span>  y latan al mismo ritmo que tu
          organización.
        </p>
        <h3>
          “Los colaboradores felices son un 88% más productivos
          que aquellos con actitud negativa.” 
        </h3>
        <p>-connectamericas.com-</p>
      </div>
      <div>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/nuevaPersona.png" alt="imagen persona" />
        </figure>
      </div>
      <div>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/Nueva_mobile+3.png" alt="imagen mobile persona" />
        </figure>
      </div>
    </InterCommInfo>
    <Cat>
      <figure>
        <img src="https://s3.amazonaws.com/atrium1.0/gato.jpg" alt="" />
      </figure>  
      <div>
        <div></div>
        <p>¿Tus colaboradores reciben los mensajes de Dirección de forma positiva?</p>
        <p> ¿Hacen suyas las ideas y compromisos de tu empresa? </p>
        <p>¿Cómo les explicas hacia dónde se </p>
        <p>dirige tu compañía para que se </p>
        <p>suban al barco contigo?</p>
      </div>
    </Cat>
    <WeAreAtrium>
      <div>
        <h2>SOMOS AURÍCULA</h2>
        <div></div>
        <p>
          Una agencia <span>especializada en comunicación interna. </span>
          Te 
        </p>
        <p>ayudamos a que tu colaboradores conozcan la filosofía</p>
        <p>de tu empresa, se vuelvan embajadores de tu marca y</p>
        <p> <span>mejoramos tus herramientas de venta y </span></p>
        <p><span> comunicación hacia tus clientes.</span> </p>
        <p>
          “Venderle” tus ideas a tus colaboradores para que las hagan
          suyas. 
        </p>
        <p>Generar conversación positiva.</p>
        <p> Comunicarles el rumbo de la empresa. </p>
        <p>Crear herramientas de ventas para tus clientes.</p>
      </div>
      <figure>
        <img src="https://s3.amazonaws.com/atrium1.0/Nuevocorazon.png" alt="corazon blanco de Auricula" />
      </figure>
      <figure>
        <img src="https://s3.amazonaws.com/atrium1.0/NuevoCorazon_mobile.png" alt="corazon blanco de Auricula" />
      </figure>
    </WeAreAtrium>
    <OurClients>
      <div>
        <h2>NUESTROS CLIENTES</h2>
      </div>
      <div>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClietesAon.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesAplus.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesBee.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesMandarina.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesCarnes.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesIqos.png" alt="logo clientes auricula" />
        </figure>
        <figure>
          <img src="https://s3.amazonaws.com/atrium1.0/ClientesBelieve.png" alt="logo clientes auricula" />
        </figure>
      </div>
    </OurClients>
    <Tools>
      <div></div>
      {/* <p>¿Quieres utilizar las herramientas </p>
      <p>que tienes a tu alcance para sacarle mejor provecho</p>
      <p>y no sabes por donde comenzar?</p> */}
      <p>Agenda una cita.</p>
      <div>
        <div>
          <a href="/contactanos">DA CLIC AQUÍ</a>
        </div>
        <div></div>
      </div>
    </Tools>
    <Footer>
      <a href="/contactanos">CONTÁCTANOS</a>
      <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos' >corazon@auricula.mx</a>
      <a href="/aviso-de-privacidad">Aviso de privacidad</a>
    </Footer>
    </>
  )
}
