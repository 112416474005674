import React, {useState}  from 'react'
import axios from 'axios'  

import {
  ModalHamburguer,
  ContUs,
  Nav,
  NavLogo,
  Navbuttons,
  MenuHamburguer,
  FormCont,
  FormContNotes,
  FormContForm,
  Footer
} from './contacUs.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'
import ModalSendMailSuccess from '../modals/sendMailSuccess/sendMailSuccess'
import ModalSendMailError from '../modals/sendMailError/sendMailError'
import ModalMailLackOfData from '../modals/sendMailLackOfData/sendMailLackOfData'

export default function Services () {
  const [ user, setUser ] = useState({})
  const [ modalHamburguer, setModalHamburguer ] = useState(false)
  const [ showModalSendMailSuccess, setShowModalSendMailSuccess ] = useState(false)
  const [ showModalSendMailError, setShowModalSendMailError ] = useState(false)
  const [ showModalMailLackOfData, setShowModalMailLackOfData ] = useState(false)
  const [ onlyNumbers, setOnlyNumbers ] = useState()

  const showModalHamburguer = () => {
    setModalHamburguer(true)
  }

  const closeModalHamburguer = () => {
    setModalHamburguer(false)
  }

  const handleData = e => {
    user[e.target.name] = e.target.value
  }

  const handleOnlyNumbers = e => {    
    const re = /^[+\d](?:.*\d)?$/;

    if (e.target.value === '' || re.test(e.target.value)) {
       setOnlyNumbers(e.target.value)
       user[e.target.name] = e.target.value
    }
  }

  const sendDataToMail = () => {
    if(Object.keys(user).length === 6) {
      axios.post('https://bzewcccs78.execute-api.us-east-1.amazonaws.com/dev/contacto',user)
        .then( () => setShowModalSendMailSuccess(true) )
        .catch( () => setShowModalSendMailError(true) )
      } else { setShowModalMailLackOfData(true) }
  }


  return (
    <>
      <ModalSendMailError showModalSendMailError={showModalSendMailError} setShowModalSendMailError={setShowModalSendMailError} />
      <ModalMailLackOfData showModalMailLackOfData={showModalMailLackOfData} setShowModalMailLackOfData={setShowModalMailLackOfData}/>
      <ModalSendMailSuccess showModalSendMailSuccess={showModalSendMailSuccess}/>
      <ModalHamburguer showModalHamburguer={modalHamburguer}>
        <div>
          <div>
            <div onClick={closeModalHamburguer}></div>
          </div>
          <div>
            <div>
              <a href="/">HOME</a>
            </div>
            <div>
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </div>
        </div>
      </ModalHamburguer>
      <WhatsApp/>
      <ContUs>
        <Nav>
          <NavLogo >
            <a href="/">
                <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_amarillo.png" alt="logotipo de auricula" />
            </a>
          </NavLogo>
          <Navbuttons>
            <div>
              <a href="/">HOME</a>
            </div>
            <div >
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </Navbuttons>
          <MenuHamburguer onClick={showModalHamburguer}>
            <div></div>
            <div></div>
            <div></div>
          </MenuHamburguer>
        </Nav>
        <FormCont>
          <FormContNotes>
            <h2>NOTAS</h2>
            <div>
              <div></div>
              <p>La cita se realizará a través de videollamada.</p>   
            </div>
            <div>
              <div></div>
              <p>La duración de la videollamada será de 45 minutos.</p>   
            </div>
            <div>
              <div></div>
              <p>No tiene ningún costo.</p>   
            </div>
            <div>
              <div></div>
              <p>La videollamada no te compromete en nada.</p>   
            </div>
            <div>
              <div></div>
              <p>
                La entrega del informe y los demás regalos se te enviarán vía
                correo electrónico en máximo 3 días.
              </p>   
            </div>
            <div>
              <div></div>
              <p>No haremos uso de tus datos de forma inadecuada.</p>   
            </div>
          </FormContNotes>
          <FormContForm>
            <h2>LLENA EL SIGUIENTE FORMULARIO Y AGENDA UNA CITA.</h2>
            <div></div>
            <div>
              <p>Nombre:</p>
              <input onChange={handleData} type="text" name='name' required />
            </div>
            <div>
              <p>Puesto:</p>
              <input onChange={handleData} type="text" name='position' required />
            </div>
            <div>
              <p>Empresa:</p>
              <input onChange={handleData} type="text" name='company' required />
            </div>
            <div>
              <p>Correo electrónico:</p>
              <input onChange={handleData} type="email" name='email' required />
            </div>
            <div>
              <p>Teléfono/WhatsApp:</p>
              <input onChange={handleOnlyNumbers} type="tel" name='phone' required value={onlyNumbers} />
            </div>
            <div>
              <div>
                <label className='container'>
                  <input  onChange={handleData}  type="checkbox" name='notice-of-privacy'/>
                  <span className='checkmark'></span>
                </label>
                <p>Aviso de privacidad</p>
              </div>
              <a href="/aviso-de-privacidad">Términos y condiciones</a>
            </div>
            <button onClick={sendDataToMail}>
              <div>
                <p>ENVIAR</p>
                <div></div>
              </div>
            </button>
          </FormContForm>
        </FormCont>
      </ContUs>
      <Footer>
        <a href="/contactanos">CONTÁCTANOS</a>
        <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos' >corazon@auricula.mx</a>
        <a href="tel:5580278707">5580278707</a>
      </Footer>
    </>
  )
}