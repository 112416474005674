import React, { useState, useEffect }  from 'react'
  
import { 
  SendMailLackOfDataContainer,
  SMS } from './sendMailLackOfData.styles'

export default function SendMailLackOfData (props) {
  const [ lackOfData, setLackOfData ] = useState(props.showModalMailLackOfData)

  useEffect( () => {
    setLackOfData(props.showModalMailLackOfData)
  },[props.showModalMailLackOfData])

  const closeModal = () => {
    setLackOfData(false)
    props.setShowModalMailLackOfData(false)
  }


  return (
    <SendMailLackOfDataContainer showModalMailLackOfData={lackOfData}>
      <SMS>
        <figure onClick={closeModal}>
          <img src="https://s3.amazonaws.com/atrium1.0/x_modal.png" alt="icono x cierre" />
        </figure>
        <h2>¡ADVERTENCIA!</h2>
        <p>Para poder enviar tu mail necesitamos que llenes todos los campos</p>
      </SMS>
    </SendMailLackOfDataContainer>
  )
}