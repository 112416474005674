import styled from 'styled-components'

import { movimiento } from '../../styles/animations'

import { 
 yellowAuricula, 
 whiteAuricula, 
 blackAuricula
} from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display:none;

@media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
    width: 100%;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    z-index: 1001;
    animation: ${movimiento} .75s linear;
      
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;

      > div:nth-child(1) {
        margin-top: 12%;
        margin-left: 80%;
        width: 30px;
        height: 3px;
        position: relative;
        border-radius: 10px;

        > div:before,
          div:after {
          content: '';
          position: absolute;
          width: 30px;
          height: 3px;
          background-color:${yellowAuricula};
          border-radius: 10px;
        }

        > div:before {
          -webkit-transform: rotate(50deg);
          -moz-transform: rotate(50deg);
          transform: rotate(50deg);
          transform: rotate(50deg);
        } 
        
        > div:after {
          -webkit-transform: rotate(-50deg);
          -moz-transform: rotate(-50deg);
          transform: rotate(-50deg);
          right: .8px;
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30%;
        
        > div:nth-child(1),
          div:nth-child(2),
          div:nth-child(3),
          div:nth-child(4),
          div:nth-child(5) {
          padding-bottom: 20%;
          
          > a {
            text-decoration: none;
            font-size: 6vw;
            font-family:'BebasNeue';
            color: ${whiteAuricula}; 
            cursor: pointer; 
            text-transform: uppercase;
            transition: all 0.5s;
            position: relative;

            &:hover {
              color:${yellowAuricula};
              background-size: 100% 2px;  
            }

            &:before {
              content: '';
              width: 0;
              height: 2px;
              background-color: ${yellowAuricula};
              position: absolute;
              top: 120%;
              left: 0;
              transition: all 0.5s;
            
            }

            &:hover:before {
              width: 50%;
              transform: translateX(100%);
            }

            &:after {
              content: '';
              width: 0;
              height: 2px;
              background-color: ${yellowAuricula};
              position: absolute;
              top: 120%;
              right: 0;
              transition: all 0.5s;
              
            }

            &:hover:after {
              width: 50%;
              transform: translateX(-100%);
            }
          }
        }
      }
    }
  }
`

export const Nav = styled.section `
  display: flex;
  padding-bottom: 5%;
  background-color: black;

  @media (min-width:600px) and (max-width: 1024px) {

  }

  @media (max-width: 600px) {
    padding-bottom: 10%;
   
  }
`
export const NavLogo = styled.section `
  margin : 5% 0 0 5%;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
      
    }
  }

  @media (max-width: 600px) {
    margin : 10% 0 0 10%;
    
    img {
      
    }
  }
  
    
`

export const InterCommNavButton = styled.div `
  display: flex;
  cursor: pointer;
  margin: 5% 0% 0% 60%;

  > div {
    padding-right:15%;

    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: white;
      text-decoration: none;
      transition: all 0.5s;
      position: relative;
      
    }

    >a:hover {
      color: ${yellowAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: flex;
    margin: 5% 0% 0% 50%;

    > div {
    
      > a {
        font-size: 2vw;
      }

      >a:hover {
       
      }

      > a:before {
       
      }

      > a:hover:before {
      
      }

      > a:after {
        
      }

      > a:hover:after {
        
      }
    }
  }

  @media (max-width: 600px)  {
    display: none;
   

    > div {
      

      > a {
        
      }

      >a:hover {
        
      }

      > a:before {
        content: '';
       
      }

      > a:hover:before {
        
      }

      > a:after {
        content: '';
       
      }

      > a:hover:after {
        
      }
    }
  }
    
`

export const MenuHamburguer = styled.div `
  display: none;
  
  &:hover{
    > div:nth-child(1) {
    }
  }

  &:hover{
    > div:nth-child(2) {
    }
  }

  &:hover{
    > div:nth-child(3) {
    }
  }

  > div:nth-child(1) {
  }

  > div:nth-child(2) {
  }

  > div:nth-child(3) {
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
      }
    }

    &:hover{
      > div:nth-child(2) {
      }
    }

    &:hover{
      > div:nth-child(3) {
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 11%;
    padding-left: 62.5%;
    cursor: pointer;

    &:hover {
      > div:nth-child(1) {
        background-color: ${yellowAuricula} ;
      }

      > div:nth-child(2) {
        background-color: ${yellowAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }

      > div:nth-child(3) {
        background-color: ${yellowAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div {
      margin-bottom: 15%;
      width: 25px;
      height: 2px;
      background-color:  ${whiteAuricula};
    }
  }
`

export const InterCommInfo = styled.div `
  display: block;
  display: flex;

  > div:nth-child(1) {
    width: 50%;

    > h2:nth-child(1) {
      padding: 8% 0 4% 15%;
      font-size: 4vw;
      font-family:'BebasNeue' ;
      color:  ${blackAuricula};
    }

    > div:nth-child(2) {
      width: 13%;
      height: 3px;
      margin: 0 0 4% 15%;
      background-color:  ${blackAuricula};
    } 

    > p:nth-child(3) {
      width: 78%;
      margin: 0 0 4% 15%;
      font-size: 1.5vw;
      font-family:'NotoSansRegular' ;

      > span {
        font-weight: bold;
      }
    }

    > h3 {
      margin-left: 15%;
      width: 80%;
      font-size: 2vw;
      font-family:'NotoSansBold' ;
    }

    > p:nth-child(5) {
      margin-left: 15%;
      font-size: 1.5vw;
      letter-spacing: -1px;
      font-family:'NotoSansRegular' ;

    }
  }

  > div:nth-child(2) {
    width: 50%;

    > figure {
      width : 100%;
      height: auto;
       
      > img {
        width: 100%;
        height: 100%; 
      }
    }
  }

  > div:nth-child(3) {
    display: none;
  } / *

  @media (min-width:600px) and (max-width: 1024px) {
  padding-bottom:6%;
  
  > div:nth-child(1) {
    width: 50%;

    > h2:nth-child(1) {
      padding: 15% 0 4% 15%;
    }

    > div:nth-child(2) {
      width: 13%;
      height: 3px;
      margin: 0 0 4% 15%;
      background-color:  ${blackAuricula};
    } 

    > p:nth-child(3) {
     
      > span {
       
      }
    }

    > h3 {
      
    }

    > p:nth-child(5) {
     
    }
  }

  > div:nth-child(2) {
  

    > figure {
      width:49.9%;
      height: 53%;
      margin-top:4.5% ;
      
      > img {
        
      }
    }
  }

   > div:nth-child(3) {
    display: none;
  }
}

@media (max-width: 600px) {
  display: block;
  display: flex;
  flex-direction: column;

  > div:nth-child(1) {
    width: 100%;

    > h2:nth-child(1) {
      padding: 15% 0 4% 8%;
      width: 50%;
      font-size: 9vw;
    }

    > div:nth-child(2) {
      width: 18%;
      margin: 0 0 4% 8%;
    } 

    > p:nth-child(3) {
      width: 85%;
      margin: 0 0 4% 8%;
      font-size: 3.5vw;

      > span {
      
      }
    }

    > h3 {
      margin-left: 8%;
      width: 90%;
      font-size: 3.5vw;
    }

    > p:nth-child(5) {
      margin-left: 8%;
      font-size: 3.5vw;
      letter-spacing: -1px;
    }
  }

  > div:nth-child(2) {
    display: none;

    > figure {

      > img {
      }
    }
  }

  > div:nth-child(3) {
    display: block;

    > figure {
      height: auto;

      > img {
        width: 100%;
        height: 100%;
      }
    }
  } / *
}
    
`

export const Cat = styled.div `
  display: flex;
  margin-top: -1.4%;

  > figure {
      width: 45%;
      height:780px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction:column;
    width: 55%;
   

    > div:nth-child(1) {
      width: 100%;
      height: 70px;
      background-color:${blackAuricula} ;
      z-index: -1000;
    }

    > p:nth-child(2) {
      width: 75%;
      padding: 21% 0 5% 15%;
      font-size: 2vw;
      font-family:'NotoSansBold';
      color:  ${blackAuricula};
    }

    > p:nth-child(3) {
      width: 70%;
      padding: 0 0 5% 15%;
      font-size: 2vw;
      font-family:'NotoSansBold';
      color:  ${blackAuricula};
    }

    > p:nth-child(4),
      p:nth-child(5),
      p:nth-child(6) {
      width: 100%;
      padding-left: 15%;
      font-size: 1.8vw;
      font-family:'NotoSansBold';
      color:  ${blackAuricula};
    }
  }
  

  @media (min-width:600px) and (max-width: 1024px) {
   

    > figure {
        height:585px;

      > img {
       
      }
    }

    > div:nth-child(2) {
    
  
      > div:nth-child(1) {
        
      }

      > p:nth-child(2) {
       
      }

      > p:nth-child(3) {
        
      }

      > p:nth-child(4),
        p:nth-child(5),
        p:nth-child(6) {
       
      }
    }      
  }
  

    
  @media (max-width: 600px) {
    display: flex;
    flex-direction:column;
    margin-top: -5%;

    > figure {
        z-index: -1000;
        width: 100%;
        height:550px;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction:column;
      width: 100%;
      padding-bottom:15%;
      

      > div:nth-child(1) {
        height: 65px;
        background-color:transparent ;
        
      }

      > p:nth-child(2) {
        width: 90%;
        padding: 0% 0 8% 5%;
        font-size: 6vw;
      }

      > p:nth-child(3) {
        width: 90%;
        padding: 0 0 8% 5%;
        font-size: 6vw;
        font-family:'NotoSansBold';
        color:  ${blackAuricula};
      }

      > p:nth-child(4),
        p:nth-child(5),
        p:nth-child(6) {
        width: 75%;
        padding-left: 5%;
        font-size: 6vw;
        font-family:'NotoSansBold';
        color:  ${blackAuricula};
      }
    }
  } 

`

export const WeAreAtrium = styled.div `
  display: flex;
  color: ${whiteAuricula};
  background-color: ${blackAuricula};
  
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    width: 60%;

    > h2 {
      width: 100%;
      padding: 15% 0 4% 15% ;
      font-size: 3.8vw;
      font-family: 'Bebasneue';
      letter-spacing: 2px;
    }

    > div:nth-child(2) {
      width: 12%;
      height: 3px;
      margin:0 0 3% 15%;
      background-color:${yellowAuricula} ;
    }

    > p:nth-child(3) {
      width: 100%;
      padding: 3% 0 0 15% ;
      font-family: 'NotoSansRegular';
      font-size: 1.5vw;

      > span {
        font-weight: bold;
      }
    }
    
    > p:nth-child(4) {
      width: 100%;
      padding-left: 15% ;
      font-family: 'NotoSansRegular';
      font-size: 1.5vw;
    }

    > p:nth-child(5) {
      width: 75%;
      padding-left: 15% ;
      font-family: 'NotoSansRegular';
      font-size: 1.5vw;
      letter-spacing: -1px;
    }

    > p:nth-child(6) {
      width: 75%;
      padding-left:15% ;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;

      > span {
        font-weight: bold;
      }
    }

    > p:nth-child(7) {
      width: 100%;
      padding-left: 15%;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;
      
      > span {
        font-weight: bold;
      }
    }

    > p:nth-child(8) {
      width: 75%;
      padding:3% 0 0 15% ;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;
    } 

    > p:nth-child(9) {
      width: 100%;
      padding:3% 0 0 15% ;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;
    } 

    > p:nth-child(10) {
      width: 100%;
      padding:3% 0 0 15% ;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;
    } 

    > p:nth-child(11) {
      width: 100%;
      padding:3% 0 0 15% ;
      font-family:'NotoSansRegular' ;
      font-size: 1.5vw;
    } 
  }

  > figure:nth-child(2) {
      width: 40%;
      height: 800px;
      margin-top:1% ;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover; 
    }
  }

  > figure:nth-child(3) {
      display: none;
      
    > img {
    }
  }
  

  @media (min-width:600px) and (max-width: 1024px) {
    
    > div:nth-child(1) {
      
      > h2 {
        padding:17.5% 0 4% 15% ;
        
      }

      > div:nth-child(2) {
      }

      > p:nth-child(3) {

        > span {
        }
      }
      
      > p:nth-child(4) {
      }

      > p:nth-child(5) {
      }

      > p:nth-child(6) {
        
        > span {  
        }
      }

      > p:nth-child(7) {
       
        > span {
        }
      }

      > p:nth-child(8) {
      } 

      > p:nth-child(9) {
      } 

      > p:nth-child(10) {
      } 

      > p:nth-child(11) {
      } 
    }

    > figure:nth-child(2) {
        height: 600px;
       
      > img {
      }
    }

    > figure:nth-child(3) {
        display: none;
      
      > img {
      }
    }
  }



  @media (max-width: 600px) {
    flex-direction:column; 
     
    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      width:100%;
      padding-bottom: 10%;

      > h2 {
        width: 100%;
        padding: 10% 0 4% 7% ;
        font-size: 9vw;
      }

      > div:nth-child(2) {
        width: 20%;
        margin:0 0 3% 7%;
      }

      > p:nth-child(3) {
        width: 75%;
        padding:3% 0 0 7% ;
        font-size: 3.5vw;

        > span {
        }
      }
      
      > p:nth-child(4) {
        width: 75%;
        padding-left:7% ;
        font-size: 3.5vw;
      }

      > p:nth-child(5) {
        width: 65%;
        padding-left: 7%;
        font-size: 3.5vw;
        letter-spacing: 0px;
      }

      > p:nth-child(6) {
        width: 90%;
        padding-left:7% ;
        font-size: 3.5vw;

        > span {
        }
      }

      > p:nth-child(7) {
        width: 100%;
        padding-left: 7%;
        font-size: 3.5vw;
        
        > span {
        }
      }

      > p:nth-child(8) {
        width: 95%;
        padding:3% 0 0 7% ;
        font-size: 3.5vw;
      } 

      > p:nth-child(9) {
        padding:3% 0 0 7% ;
        font-size: 3.5vw;
      } 

      > p:nth-child(10) {
        padding:3% 0 0 7% ;
        font-size: 3.5vw;
      } 

      > p:nth-child(11) {
        padding:3% 0 0 7% ;
        font-size: 3.5vw;
      } 
    }

    > figure:nth-child(2) {
      display: none;
      
      > img {
      }
    }

    > figure:nth-child(3) {
        display: block;
        width: 100%;
        height: 600px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
      
` 

export const OurClients = styled.section `
  display: flex;
  flex-direction: column;
  padding-bottom: 10%;

  > div:nth-child(1) {

      > h2 {
      margin-top: 3%;
      font-size:4vw;
      font-family:'BebasNeue';
      text-align:center;
      letter-spacing: 2px;
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-evenly;
    padding-top:5% ;

    > figure:nth-child(1) {
      width:12%;

      > img { 
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(2) {
      width:12%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(3) {
      width:12%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(4) {
      width:12%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(5) {
      width:12%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(6) {
      width:12%;

      > img {
        width: 100%;
        height: 100%;
      }
    }

    > figure:nth-child(7) {
      width:12%;
      

      > img {
        margin-top:-5% ;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {

        > h2 {
        
      }
    }

    > div:nth-child(2) {
      

      > figure:nth-child(1) {
        

        > img { 
          
        }
      }

      > figure:nth-child(2) {
       

        > img {
          
        }
      }

      > figure:nth-child(3) {
       

        > img {
         
          
        }
      }

      > figure:nth-child(4) {
       

        > img {
          
        }
      }

      > figure:nth-child(5) {
       

        > img {
          
        }
      }

      > figure:nth-child(6) {
       

        > img {
         
        }
      }

      > figure:nth-child(7) {
       

        > img {
         
        }
      }
    }
  }
    
  @media (max-width: 600px) {
    padding-bottom: 10%;

    > div:nth-child(1) {

        > h2 {
        margin-top: 12%;
        font-size:9vw;
        letter-spacing: 1px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding-top: 5%;

      > figure:nth-child(1) {
        width:30%;

        > img { 
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(2) {
        width:30%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(3) {
        width:30%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(4) {
        width:30%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(5) {
        width:30%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(6) {
        width:30%;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > figure:nth-child(7) {
        width:30%;
        

        > img {
          margin-top:-5% ;
          width: 100%;
          height: 100%;
        }
      }
    }
  }  
`


export const Tools = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  background:black;
  padding-bottom: 8%;

  > div:nth-child(1) {
    width: 7%;
    height: 3px;
    margin: 8% 0 2% 0;
    background-color: ${yellowAuricula};
  }

  > p {
    font-size: 2vw;
    font-family:'NotoSansBold' ;
    color: white;
  }

  > div:nth-child(3) {
    display: flex;
    width: 7.5%;
    margin-top: 3%;
    background-color:transparent;
    cursor: pointer;
    transition: all linear  1s;

    &:hover {
      background: ${yellowAuricula};
      transition: all 1s;
    }

    &:hover {

      > div:nth-child(1) > a {
        color:${blackAuricula};
        transition: all 1s;
      }

      > div:nth-child(2) {
        border-left: 2.2em solid ${blackAuricula};
        transition: all 1s;
      }
    }

    > div {
    display: flex;
    flex-direction: column;

      > a {
        width: 50%;
        font-size:3vw;
        font-family:'BebasNeue' ;
        font-weight: bold;
        text-decoration: none;
        letter-spacing: 1px;
        color: white;
        line-height: 85%;
        margin-top: 1%;
        margin-left: 1%;
      }
    }

    > div:nth-child(2) {
      margin: 28% 0 0 6% ;
      width: 0; 
      height: 0; 
      border-left: 2.2em solid  white;
      border-top: 1.5em solid transparent;
      border-bottom: 1.5em solid transparent;
      transition: all linear .5s;
    }
  }
  
  @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {
     
    }

    > p {
      
    }

    > div:nth-child(3) {
      

      &:hover {
        
      }

      &:hover {

        > div:nth-child(1) > a {
          
        }

        > div:nth-child(2) {
          
        }
      }

      > div {
      

        > a {
        
        }
      }

      > div:nth-child(2) {
        margin: 28% 0 0 6% ;
        width: 0; 
        height: 0; 
        border-left: 1.8em solid  white;
        border-top: 1.3em solid transparent;
        border-bottom: 1.3em solid transparent;
        transition: all linear .5s;
      }
    }
  }
    

  @media (max-width: 600px) {
    padding-bottom: 20%;

    > div:nth-child(1) {
      width: 40%;
      margin: 15% 0 10% 0;
    }

    > p {
      width: 85%;
      text-align: center;
      font-size: 5vw;
    }

    > div:nth-child(3) {
      display: flex;
      width: 22%;
      margin-top: 8%;
      background-color:transparent;
      cursor: pointer;
      transition: all linear  1s;

      &:hover {
      
        
      }

      &:hover {

        > div:nth-child(1) > a {
         
        }

        > div:nth-child(2) {
          border-left: 1.8em solid ${blackAuricula};
          transition: all 1s;
        }
      }

      > div {
      

        > a {
          width: 90%;
          font-size:8vw;
          line-height: 85%;
          margin-top: 5%;
          margin-left: 1%;
        }
      }

      > div:nth-child(2) {
        margin: 28% 0 0 6% ;
        width: 0; 
        height: 0; 
        border-left: 1.8em solid  white;
        border-top: 1em solid transparent;
        border-bottom: 1em solid transparent;
        transition: all linear .5s;
      }
    }  
  }
  
 `


export const Footer = styled.footer `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:${yellowAuricula};
  padding: 4% 0;

  > a:nth-child(1) {
    padding-top:4%;
    font-size: 3vw;
    font-family: 'BebasNeue' ;
    text-decoration: none;
    color: ${blackAuricula};
  }
  
  > a:nth-child(2) {
    font-size: 1.5vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(3) {
    margin-top:.8%;
    font-size: 1.1vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  @media (min-width:600px) and (max-width: 1024px) {
   

    > a:nth-child(1) {
      font-size: 4vw;
      
    }
    
    > a:nth-child(2) {
      font-size: 2vw;
    }

    > a:nth-child(3) {
      font-size: 1.5vw;
    }
  }

  @media (max-width: 600px) {
    padding: 10% ;

    > a:nth-child(1) {
      font-size: 8vw;
    
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
     
    }

    > a:nth-child(3) {
      font-size: 3.2vw;
    }
  }
`