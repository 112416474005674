import React, {useState} from 'react'

import {
  ModalHamburguer,
  Nav,
  NavLogo,
  NavButton,
  MenuHamburguer,
  Video,
  VideoIpad,
  VideoMobile,
  Footer
  
} from './reel.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'

export default function Reel (props) {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)

  const sendToQuotation = () => props.history.push('/cotizacion')

  const showModalHamburguer = () => setModalHamburguer(true)

  const closeModalHamburguer = () => setModalHamburguer(false)

  return ( 
    <>
    <ModalHamburguer showModalHamburguer={modalHamburguer}>
      <div>
        <div>
          <div onClick={closeModalHamburguer}></div>
        </div>
        <div>
          <div>
            <a href="/">HOME</a>
          </div>
          <div>
            <a href="/servicios">SERVICIOS</a>
          </div>
          <div>
            <a href="/reel">REEL</a>
          </div>
          <div>
            <a href="http://www.blog.auricula.mx">BLOG</a> 
          </div>
          <div>
            <a href="/contactanos">CONTACTO</a>
          </div>
        </div>
      </div>
    </ModalHamburguer>
    <WhatsApp/>
      <Nav>
        <NavLogo>
          <a href="/">
            <figure>
              <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_amarillo.png" alt="logo auricula" />
            </figure>
          </a>
        </NavLogo>
        <NavButton>
          <div>
            <a href="/">HOME</a>
          </div>
          <div>
            <a href="/servicios">SERVICIOS</a>
          </div>
          <div>
            <a href="/reel">REEL</a>
          </div>
          <div>
            <a href="http://www.blog.auricula.mx">BLOG</a> 
          </div>
          <div>
            <a href="/contactanos">CONTACTO</a>
          </div>
        </NavButton>
        <MenuHamburguer onClick={showModalHamburguer}>
          <div></div>
          <div></div>
          <div></div>
        </MenuHamburguer>
      </Nav>
      <Video>
        <video height="700px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+FULL+HORIZONTAL.mp4" type="video/mp4" />
        </video>
      </Video>
      <VideoIpad >
        <video height="500px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+FULL+HORIZONTAL.mp4" type="video/mp4" />
        </video>
      </VideoIpad >
      <VideoMobile >
        <video height="600px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+FULL+VERTICAL.mp4" type="video/mp4" />
        </video>
      </VideoMobile >
      {/* <Footer>
        <a href="/contactanos">CONTÁCTANOS</a>
        <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos' >corazon@auricula.mx</a>
        <a href="/aviso-de-privacidad">Aviso de privacidad</a>
      </Footer> */}
    </>
  )
}