import React, {useState} from 'react'

import {
  PageWebInfo,
  Video,
  VideoIpad,
  VideoMobile,
  Quote,
  ContacUs
} from './test.styles'

import Nav from '../layout/nav/nav'


export default function WebPageAndProgramming (props) {
  console.log('Estos son los props', props.match.params.valorquecambia)
  const [ modalHamburguer, setModalHamburguer ] = useState(false)
  const [test1, setTest1] = useState(false)
  const [ruta, setRuta] = useState(props.match.params.valorquecambia)
  // const [pruebas2, setPruebas2] = useState(false)
  

  const sendToQuotation = () => props.history.push('/cotizacion')

  const showModalHamburguer = () => setModalHamburguer(true)

  const closeModalHamburguer = () => setModalHamburguer(false)

  const mousePrueba = () =>  setTest1(!test1)

  // const prueba2 = (evento) => {
  //   console.log(evento)
  //   console.log('page x', evento.pageX)
  //   console.log('page y', evento.pageY)
  //   console.log('relatedTarget', evento.relatedTarget.attributeStyleMap.size)

  //   if(evento.pageX >= 400 ){
  //     console.log('Soy mayor a 400')
  //     setPruebas2('Mayor')

  //   }else {
  //     console.log('Soy menor a 400')
  //     setPruebas2('Menor')
  //   }
  // }
  
  return ( 
    <>
      <Nav barraPrueba/>
      <PageWebInfo>
        <h2>PÁGINAS WEB </h2>
        <h2>& PROGRAMACIÓN</h2>
        <div></div>
        <p>
          En <span>Aurícula,</span> contamos con un equipo exclusivo para
          desarrollo de <span>páginas web a la medida.</span> Desde la
          funcionalidad, el diseño, la programación y complementos.
        </p>
        <p>
          No importa si eres una Pyme y buscas una landing page, o
          si quieres una tienda en línea o una página web, podemos
          realizar cualquier tipo de sitio <span>optimizado a tus necesidades.</span>
        </p>
      </PageWebInfo>
      <Video>
        <video height="700px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </Video>
      <VideoIpad>
        <video height="500px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+HORIZONTAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </VideoIpad>
      <VideoMobile>
        <video height="600px"  autoPlay loop controls muted >
            <source src="https://s3.amazonaws.com/atrium1.0/PORTAFOLIO+AURICULA+VERTICAL+PROGRAMACION.mp4" type="video/mp4" />
        </video>
      </VideoMobile>
      <Quote >
      {/* MouseUp = { mousePrueba } cambiocolor = {test1}  */}
        <div></div>
        <p >Solicita tu cotización fácilmente</p>
        <div onClick={sendToQuotation}>
          <div>
            <a href="/cotizacion">DA CLIC AQUÍ</a>
          </div>   
          <div></div> 
        </div>
      </Quote>
      <ContacUs>
      {/* onMouseOver={prueba2} changeColor = {pruebas2} */}
        <a href="/contactanos">CONTACTANOS</a>
        <a href='mailto:corazon@auricula.mx?subject=Quiero contactarlos'>corazon@auricula.mx</a>
      </ContacUs>
    </>
  )
}