import React, {useState,useEffect}  from 'react'
  
import { 
  SendMailErrorContainer,
  SMS } from './sendMailError.styles'

export default function SendMailError (props) {
  const [ showModalSendMailError, setShowModalSendMailError ] = useState(props.showModalSendMailError)

  useEffect( () => {
    setShowModalSendMailError(props.showModalSendMailError)
  },[props.showModalSendMailError])

  const closeModal = () => {
    setShowModalSendMailError(false)
    props.setShowModalEmailNotSent(false)
  }


  return (
    <SendMailErrorContainer showModalSendMailError={showModalSendMailError}>
      <SMS>
        <figure onClick={closeModal}>
          <img src="https://s3.amazonaws.com/atrium1.0/x_modal.png" alt="icono x cierre" />
        </figure>
        <h2>¡ERROR!</h2>
        <p>Lo sentimos por el momento no podemos enviar tu correo.</p>
      </SMS>
    </SendMailErrorContainer>
  )
}