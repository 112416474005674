import styled from 'styled-components'

import { movimiento } from '../../../styles/animations'

import { 
  pinkAuricula,
  blackAuricula,
  whiteAuricula,
  

} from '../../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  
  }

  @media (max-width: 600px) {
  display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
  width: 100%;
  height: 100vh;
  background-color: black;
  position:absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  animation: ${movimiento} .75s linear;
    
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div:nth-child(1) {
      margin-top: 12%;
      margin-left: 80%;
      width: 23px;
      height: 2px;
      position: relative;
      border-radius: 2px;

      > div:before,
        div:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color:${pinkAuricula};
        border-radius: 10px;
      }

      > div:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        left: -9px;
      } 
      
      > div:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 2px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top:45%;
      
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4) {
        padding-bottom:25%;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color: ${whiteAuricula}; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;

          &:hover {
            color:${pinkAuricula};
            background-size: 100% 2px;  
          }

          &:before {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${pinkAuricula};
            position: absolute;
            top: 120%;
            left: 0;
            transition: all 0.5s;
          }

          &:hover:before {
            width: 50%;
            transform: translateX(100%);
          }

          &:after {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${pinkAuricula};
            position: absolute;
            top: 120%;
            right: 0;
            transition: all 0.5s;
          }

          &:hover:after {
            width: 50%;
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}

`

export const Nav = styled.nav `
  display: flex;
  padding-bottom: 5%;
  background:black;
  
  @media (min-width:600px) and (max-width: 1024px) {
    
  }

  @media (max-width: 600px) {
    padding-bottom: 10%;   
  }
`

export const NavLogo = styled.section `
  margin : 5% 0 0 5%;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
y
    }
  }

  @media (max-width: 600px) {
    margin: 10% 0 0 10%;
    
    img {
    }
  }
`

export const NavButton = styled.div `
  display: flex;
  cursor: pointer;
  margin: 5% 0% 0% 65%;

  div {
    padding-right:15%;
    
    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: ${whiteAuricula};
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative; 
    }

    > a:hover {
      color: ${pinkAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${pinkAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${pinkAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }

  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 58%;

    div{

      > a {
       font-size: 2vw;
      }

      > a:hover {
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;
    margin: 5% 0% 0% 10%;

    div{

      > a {
      }

      > a:hover { 
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }  
    }
  }
`

export const MenuHamburguer = styled.div `
  cursor: pointer;
  display: none;
  
  &:hover{
    > div:nth-child(1) {
      background-color:${pinkAuricula}
    }
  }

  &:hover{
    > div:nth-child(2) {
      background-color:${pinkAuricula} ;
      width: 60%;
      transition: all 0.2s linear;
    }
  }

  &:hover{
    > div:nth-child(3) {
      background-color:${pinkAuricula} ;
      width: 20%;
      transition: all 0.2s linear;
    }
  }

  > div:nth-child(1) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  > div:nth-child(2) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  > div:nth-child(3) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
      }
    }

    &:hover{
      > div:nth-child(2) {
      }
    }

    &:hover{
      > div:nth-child(3) {
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    cursor: pointer;
    display: inline-block;
    
    &:hover{
      > div:nth-child(1) {
        background-color: ${pinkAuricula} ;
      }

      > div:nth-child(2) {
        background-color: ${pinkAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }

      > div:nth-child(3) {
        background-color: ${pinkAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div:nth-child(1) {
      width: 25px;
      height: 2px;
      margin: 150% 0 2% 850%;
      background-color:  ${whiteAuricula};
    }

    > div:nth-child(2) {
      width: 25px;
      height: 2px;
      margin: 12% 0 2% 850%;
      background-color:  ${whiteAuricula};
    }

    > div:nth-child(3) {
      width: 25px;
      height: 2px;
      margin: 12% 0 2% 850%;
      background-color:  ${whiteAuricula};
    }
  }
`

