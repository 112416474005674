import React, { useState, useEffect }  from 'react'
  
import { 
  SendMailSuccessContainer,
  SMS } from './sendMailSuccess.styles'

export default function SendMailSuccess (props) {
  const [ showModalSendMailSuccess, setShowModalSendMailSuccess ] = useState(props.showModalSendMailSuccess)

  useEffect( () => {
    setShowModalSendMailSuccess(props.showModalSendMailSuccess)
  },[props.showModalSendMailSuccess])

  const closeModal = () => {
    setShowModalSendMailSuccess(false)
    window.location.reload()
  }


  return (
    <SendMailSuccessContainer showModalSendMailSuccess={showModalSendMailSuccess}>
      <SMS>
        <figure onClick={closeModal}>
          <img src="https://s3.amazonaws.com/atrium1.0/x_modal.png" alt="icono x cierre" />
        </figure>
        <h2>¡GRACIAS!</h2>
        <p>En breve un asesor te contactará</p>
      </SMS>
    </SendMailSuccessContainer>
  )
}