import styled from "styled-components"

import { 
  whiteAuricula,
  yellowAuricula
} from '../../../styles/colorStyles'


export const SendMailErrorContainer = styled.section `
  position: fixed;
  display: ${props=> props.showModalSendMailError ? 'flex' : 'none' }; 
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background: rgba(0,0,22,0.40);
  
  @media (min-width:600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  }

  @media (min-width:1600px) and (max-width: 2560px) {
  }
`

export const SMS = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 450px;
  background-position-y: 40%;
  background-size: cover;
  background-image: url("https://s3.amazonaws.com/atrium1.0/modal_corazon.png");


  figure {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    > img {
    }
  }

  > h2 {
    color: ${yellowAuricula};
    font-family: 'BebasNeue';
    font-size: 3.5vw;
    letter-spacing: 1px;
  }

  > p {
    color: ${whiteAuricula};
    font-family: 'NotoSansRegular';
    font-size: 1.5vw;
  }


  @media (min-width:600px) and (max-width: 1024px) {
   
    figure {

      > img {
        height: 30px;
      }
    }

    > h2 {
      margin-top: 12%;
    }

    > p {
      
    }
 
  }

  @media (max-width: 600px) {
    display: flex;
    width: 82%;
    height: 600px;
    background-image: url("https://s3.amazonaws.com/atrium1.0/Moda_Mobile.png");

    figure {

      > img {
        
      }
    }

    > h2 {
      font-size: 12vw;
      margin-top: 40%;
    }

    > p {
      font-size: 4vw;
      width: 90%;
      text-align: center;
    }
  }

  @media (min-width:1600px) and (max-width: 2560px) {
    height: 900px;

    figure {
      right: 15px;
      top: 15px;

      > img {
        width: 100%;
        height: 60px;
      }
    
    }

    > h2 {
      
    }

    > p {
     
    }
  }
    

`