import styled from "styled-components"

import { movimiento } from '../../styles/animations'

import { blackAuricula, whiteAuricula, yellowAuricula } from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  }

  @media (max-width: 600px) {
  display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
  width: 100%;
  height: 100vh;
  background-color: black;
  position:absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  animation: ${movimiento} .75s linear;
    
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    

    > div:nth-child(1) {
      margin-top: 12%;
      margin-left: 80%;
      width: 23px;
      height: 2px;
      position: relative;
      border-radius: 2px;
      

      > div:before,
        div:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: ${yellowAuricula};
        border-radius: 10px;
      }

      > div:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        left: -9px;
      } 
      
      > div:after{
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 2px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top:45% ;
      
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4) {
        padding-bottom: 25%;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color:white; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;
        }

        > a:hover {
          color:${yellowAuricula};
          background-size: 100% 2px;  
        }

        > a:before {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          left: 0;
          transition: all 0.5s;
        }

        > a:hover:before {
          width: 50%;
          transform: translateX(100%);
        }

        > a:after {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          right: 0;
          transition: all 0.5s;
        }

        > a:hover:after {
          width: 50%;
          transform: translateX(-100%);
        }  
      }
    }
  }
}

`

export const Nav = styled.section `
  display: flex;
  background: ${blackAuricula};
  padding-bottom:3% ;

  @media (min-width:600px) and (max-width: 1024px) {
     
  }

  @media (max-width: 600px) {
    padding-bottom:10% ;
  }

`

export const NavLogo = styled.section `
  margin : 5% 0 0 5% ;
  width: 40px;
  height: 130px;
 

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
      
    }
  }

  @media (max-width: 600px) {
    margin : 10% 0 0 10% ;
    
    img {
      
    }
  }

`

export const NoticeTitle = styled.div `
  display:flex ;
  color :red;
  margin:12.5% 0 0 35%;

  > h2 {
    color: ${yellowAuricula};
    font-family: 'BebasNeue';
    font-size: 1.8vw;
    letter-spacing: 2px;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display:flex ;
    margin:15% 0 0 31.2%;

    > h2 {
      font-size: 2.5vw;
    }
  }

  @media (max-width: 600px) {
    display: none;
    

    > h2 {
      
    }
  }

`

export const NoticeTitleMobile = styled.div `
  display: none;
  

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: inline-block;
    margin-top:50%;

    > h4 {
      font-size: 6vw;
      letter-spacing: 2px;
      color: ${yellowAuricula};
      font-family: 'BebasNeue'
    }
  }
` 



export const Navbuttons = styled.section `
  display: flex;
  margin: 5% 0% 0% 17%;
  
    
  > div {
    padding-right:15%;
   

    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative;
      
    }

    > a:hover {
      color:${yellowAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }
  
  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 7%;

      
    div{


      > a {
       font-size: 2vw;
      }

      > a:hover {
       
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
       
      }

      > a:hover:after {
        
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;
    margin: 5% 0% 0% 10%;

    div{
      padding-right:15%;

      > a {
        font-size: 3vw;  
      }

      > a:hover {
        
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
        
      }

      > a:hover:after {
       
      }  
    }
  }

`

export const MenuHamburguer = styled.div `
  cursor: pointer;
  display: none;
  
  &:hover{
    > div:nth-child(1) {
      background-color:${yellowAuricula} ;
    }
  }

  &:hover{
    > div:nth-child(2) {
      background-color:${yellowAuricula} ;
      width: 60%;
      transition: all 0.2s linear;
    }
  }

  &:hover{
    > div:nth-child(3) {
      background-color:${yellowAuricula} ;
      width: 20%;
      transition: all 0.2s linear;
    }
  }

  > div:nth-child(1) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  > div:nth-child(2) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  > div:nth-child(3) {
    width: 30px;
    height: 2px;
    margin: 30% 0 5% 0;
    background-color: white;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
        
      }
    }

    &:hover{
      > div:nth-child(2) {
        
      }
    }

    &:hover{
      > div:nth-child(3) {
        
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    cursor: pointer;
    display: block;
  
    &:hover{
      > div:nth-child(1) {
        background-color:${yellowAuricula} ;
      }
    }

    &:hover{
      > div:nth-child(2) {
        background-color:${yellowAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }
    }

    &:hover{
      > div:nth-child(3) {
        background-color:${yellowAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div:nth-child(1) {
      width: 25px;
      height: 2px;
      margin: 150% 0 2% 1050%;
      background-color: white;
      
    }

    > div:nth-child(2) {
      width: 25px;
      height: 2px;
      margin: 12% 0 2% 1050%;
      background-color: white;
    }

    > div:nth-child(3) {
      width: 25px;
      height: 2px;
      margin: 12% 0 2% 1050%;
      background-color: white;
    }
  }
`

export const Bottoms = styled.div `
  display: flex;
  flex-direction: column;
  align-items:center;
  width: 100%;
  padding: 5% 0 10% 0;

  > div:nth-child(1) {
    margin-top:3%;
    
    > a {
      font-size:2vw;
      font-family:'NotoSansRegular';
      text-decoration:none;
      color: ${yellowAuricula};
    }
  }

  > div:nth-child(2) {

   > a {
      font-size:2vw;
      font-family:'NotoSansRegular';
      text-decoration:none;
      color: ${whiteAuricula};
    }
  }
  

  @media (min-width:600px) and (max-width: 1024px) {
    padding: 5% 0 10% 0;

    > div:nth-child(1) {
      margin-top:12%;
      
      > a {
        font-size:2.5vw;
        font-family:'NotoSansRegular';
        text-decoration:none;
        color: ${yellowAuricula};
      }
    }

    > div:nth-child(2) {

    > a {
        font-size:2.5vw;
        font-family:'NotoSansRegular';
        text-decoration:none;
        color: ${whiteAuricula};
      }
    }
  }
  
  @media (max-width: 600px) {

    > div:nth-child(1) {
      margin-top:49%;
      
      > a {
        font-size:6vw;
      }
    }

    > div:nth-child(2) {

      > a {
          font-size:6vw;
          
          }
        }
    }
`

export const NoticeOfPrivacyCont = styled.section `
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 3% auto;

  > p:nth-child(1) {
    font-family:'NotoSansRegular';
    font-size:1vw;
  }

  @media (min-width:600px) and (max-width: 1024px) {
  }
  
  @media (max-width: 600px) {
    display: block;
    width: 80%;
    margin: 10% auto;

    > p:nth-child(1) {
      margin-bottom:7% ;
      font-size: 3vw;
    }
  }
`

export const Identity = styled.div `

  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family:'NotoSansRegular';
    font-size:1vw;

    > span {
      font-weight: bold;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {

    > h4 {
    }

    > p {

      > span {
      }
    }
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size:4vw;
    }

    > p  {
      margin-bottom:7% ;
      font-size: 3vw;

      > span {
      }
    }
  }
`

export const PersonalData = styled.div `

  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;
  }

  > ul {
    margin-left: .5%;
    list-style: none;
    margin-top: 1%;
    margin-bottom: 2%;

    > li {
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-indent: -1.2%;
      padding-left: 1%;
      
      &::before {
        content: "• ";
        color: ${yellowAuricula};
      }
    } 
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
    }

    > ul {

      > li {
        
        &:before {
        }
      }
    }
    
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size:3vw;
    }

    ul {
      margin-left:1%;

      > li {
        font-size:3vw;
      }
    }
    
  }
`
  
  
export const SensibleData = styled.div `

  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
    }  
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size:3vw;
    }
  }
`

export const Traitment = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;
    
  }

  > ul {
    margin-left: .5%;
    list-style: none;
    margin-top: 1%;
    margin-bottom: 2%;

    > li {
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-indent: -1.2%;
      padding-left: 1%;
      
      &::before {
        content: "• ";
        color: ${yellowAuricula};
      }
    }
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
    }

    > ul {

      > li {
        
        &:before {
        }
      }
    }
    
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size:3vw;
    }

    ul {
      margin-left:1%;

      > li {
        font-size:3vw;
      }
    }
  }
`


export const Negative = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    } 
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
      > a {     
    } 
    }  
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size: 3vw;

      > a {
        font-size: 3vw;    
      }
    }
  }
`

export const Solicitud = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > span {
      font-weight: bold;
    }

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    } 
  }

  > ul {
    margin-left: .5%;
    list-style: none;
    margin-top: 1%;
    margin-bottom: 2%;

    > li {
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-indent: -1.2%;
      padding-left: 1%;
      
      &::before {
        content: "• ";
        color: ${yellowAuricula};
      }  
    }
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
      > span {
      }

      > a {    
      }
    }

    > ul {

      > li {
        
        &:before {
        }
      }
    }
    
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size:3vw;

      > span {
      }

      > a {
        font-size: 1vw;  
      }
    }

    ul {
      margin-left:1%;

      > li {
        font-size:3vw;
      }
    }
  }
`


export const Revocacion = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    } 
  }

  > ul {
    margin-left: .5%;
    list-style: none;
    margin-top: 1%;
    margin-bottom: 2%;

    > li {
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      
      &::before {
        content: "• ";
        color: ${yellowAuricula};
      }
    }
  }


  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {
      > a {    
      } 
    }

    > ul {

      > li {
        
        &:before {
        }
      }
    }
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size:3vw;

      > a {
        font-size: 3vw;  
      }
    }

    ul {
      margin-left:1%;

      > li {
        font-size: 1vw;
      }
    }
  }
`

export const Divulgacion = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
    
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > span {
      font-weight: bold;
    }

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {

      > span {
        font-weight: bold;
      }

      > a {    
      } 
    }
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
     
    }

    > p {
      font-size:3vw;

      > span {
        font-weight: bold;
      }

      > a {
        font-size: 1vw;  
      }
    }
  }

`

export const Cookies = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > span {
      font-weight: bold;
    }

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {

      > span {
        font-weight: bold;
      }

      > a {    
      } 
    }
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size: 3vw;

      > span {
        font-weight: bold;
      }

      > a {
        font-size: 1vw;  
      }
    }
  }
`


export const Transferencia = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > span {
      font-weight: bold;
    }

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none; 
      
    }
  }

  > h5 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 2%;
    margin-left: 2%;
  }

  > ul {
    margin-left: 2%;
    list-style: none;
    margin-top: 1%;
    margin-bottom: 2%;

    > li {
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-indent: -1.2%;
      padding-left: 1%;
      
      &::before {
        content: "• ";
        color: ${yellowAuricula};
      }
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {

      > span {
        font-weight: bold;
      }

      > a {    
      } 
    }

    > h5 {
    }

    > ul {

      > li {
        
        &::before {
        }
      }
    }
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size: 3vw;

      > span {
        font-weight: bold;
      }

      > a {
        font-size: 3vw;  
      }
    }

    > h5 {
      font-size: 3vw;
    }

    > ul {

      > li {
        font-size: 3vw;
        
        &::before {
        }
      }
    }
  }
`


export const Cambios = styled.div `
  > h4 {
    font-family: 'BebasNeue';
    font-size: 1.3vw;
    margin-top: 3%;
  }

  > p {
    font-family: 'NotoSansRegular';
    font-size: 1vw;

    > a {
      color: ${blackAuricula};
      font-weight: bold;
      font-family: 'NotoSansRegular';
      font-size: 1vw;
      text-decoration:none;       
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
    > h4 {
    }

    > p {

      > a {
      }
    }    
  }
  
  @media (max-width: 600px) {

    > h4 {
      font-size: 4vw;
    }

    > p {
      font-size: 3vw;

      > a {
        font-size: 3vw;    
      }
    }
  }
`



export const Footer = styled.footer `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:${yellowAuricula};
  padding:4% 0;

  > a:nth-child(1) {
    padding-top:4%;
    font-size: 3vw;
    font-family: 'BebasNeue' ;
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(2) {
    font-size: 1.5vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > a:nth-child(1) {
      font-size: 4vw;
    }

    > a:nth-child(2) {
      font-size: 2vw;
    }   
  }

  @media (max-width: 600px) {
    padding: 10% ;

    > a:nth-child(1) {
      font-size: 8vw; 
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
    }
  }

`