import styled from 'styled-components'

import { movimiento } from '../../styles/animations'
import { 
 whiteAuricula, 
 yellowAuricula,
 blackAuricula

} from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  
  }

  @media (max-width: 600px) {
  display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
  width: 100%;
  height: 100vh;
  background-color: black;
  position:absolute;
  top: 0;
  left: 0;
  background-color: black;
  z-index: 1000;
  animation: ${movimiento} .75s linear;
    
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div:nth-child(1) {
      margin-top: 12%;
      margin-left: 80%;
      width: 30px;
      height: 3px;
      position: relative;
      border-radius: 10px;

      > div:before,
       div:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 3px;
        background-color:${yellowAuricula};
        border-radius: 10px;
      }

      > div:before {
        -webkit-transform: rotate(50deg);
        -moz-transform: rotate(50deg);
        transform: rotate(50deg);
        
      } 
      
      > div:after {
        -webkit-transform: rotate(-50deg);
        -moz-transform: rotate(-50deg);
        transform: rotate(-50deg);
        right: .8px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top:30% ;
      
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4),
        div:nth-child(5) {
        padding-bottom: 20%;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color: ${whiteAuricula}; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;

          &:hover {
            color:${yellowAuricula};
            background-size: 100% 2px;  
          }

          &:before {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${yellowAuricula};
            position: absolute;
            top: 120%;
            left: 0;
            transition: all 0.5s;
          }

          &:hover:before {
            width: 50%;
            transform: translateX(100%);
          }

          &:after {
            content: '';
            width: 0;
            height: 2px;
            background-color: ${yellowAuricula};
            position: absolute;
            top: 120%;
            right: 0;
            transition: all 0.5s;
          }

          &:hover:after {
            width: 50%;
            transform: translateX(-100%);
          }
        }
      }
    }
  }
}
`

export const Nav = styled.nav `
  display: flex;
  padding-bottom: 5%;
  background:black;
  
  @media (min-width:600px) and (max-width: 1024px) {
    
  }
  @media (max-width: 600px) {
    padding-bottom: 5%;
    background: black;
  }
`

export const NavLogo = styled.section `
  margin : 5% 0 0 5%;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
    }
  }

  @media (max-width: 600px) {
    margin: 10% 0 0 10%;
    
    img {
    }
  }
`

export const NavButton = styled.div `
  display: flex;
  margin: 5% 0% 0% 60%;

  div {
    padding-right:15%;
    
    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: ${whiteAuricula};
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative; 
    }

    > a:hover {
      color: ${yellowAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }

  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 50%;

    div{

      > a {
       font-size: 2vw;
      }

      > a:hover {
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;

    div{

      > a {
      }

      > a:hover { 
      }

      > a:before {
      }

      > a:hover:before {
      }

      >  a:after {
      }

      > a:hover:after {
      }  
    }
  }
`

export const MenuHamburguer = styled.div `
  display: none;
  
  &:hover{
    > div:nth-child(1) {
   
      }
    }
  }

  &:hover{
    > div:nth-child(2) {
     
    }
  }

  &:hover{
    > div:nth-child(3) {
     
    }
  }

  > div:nth-child(1) {
  
  }

  > div:nth-child(2) {
   
  }

  > div:nth-child(3) {
    
  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
      }
    }

    &:hover{
      > div:nth-child(2) {
      }
    }

    &:hover{
      > div:nth-child(3) {
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 11%;
    padding-left: 62.5%;
    cursor: pointer;
    
    &:hover{
      > div:nth-child(1) {
        background-color:${yellowAuricula} ;
        }
      }
    }

    &:hover{
      > div:nth-child(2) {
        background-color:${yellowAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }
    }

    &:hover{
      > div:nth-child(3) {
        background-color:${yellowAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div {
      margin-bottom: 15%;
      width: 25px;
      height: 2px;
      background-color: white;
    }
  }
`

export const Video = styled.section `
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 6% 0 6% 0;
  background: black;

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: none;
  }
 `

export const VideoIpad = styled.section  `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }

  @media (max-width: 600px) {
    display: none;
  }
`

export const VideoMobile = styled.section `
  display: none;

@media (min-width:600px) and (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 600px) {
    display: inline-block;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6% 0 6% 0;
    background: black;
  }

`

export const Footer = styled.section `
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color:${yellowAuricula} ;
  padding: 4% 0;

  > a:nth-child(1) {
    padding-top:4%;
    font-size: 3vw;
    font-family: 'BebasNeue' ;
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(2) {
    font-size: 1.5vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  > a:nth-child(3) {
    margin-top:.8%;
    font-size: 1.1vw;
    font-family: 'NotoSansRegular';
    text-decoration: none;
    color: ${blackAuricula};
  }

  @media (min-width:600px) and (max-width: 1024px) {

    > a:nth-child(1) {
      font-size: 4vw;
    }

    > a:nth-child(2) {
      font-size: 2vw;
    }

    > a:nth-child(3) {
      font-size: 1.5vw;
      
    }
  }

  @media (max-width: 600px) {
    padding: 10% ;

    > a:nth-child(1) {
      font-size: 8vw;
    }

    > a:nth-child(2) {
      font-size: 4.5vw;
    }

    > a:nth-child(3) {
      font-size: 3.2vw;
    }
  }
`

