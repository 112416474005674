import styled from "styled-components"

import { movimiento } from '../../styles/animations'

import { 
  yellowAuricula,
  pinkAuricula,
  greyAuricula

} from '../../styles/colorStyles'

export const ModalHamburguer = styled.div `
  display: none;

  @media (min-width:600px) and (max-width: 1024px) {
  
  }

  @media (max-width: 600px) {
  display: ${props => props.showModalHamburguer ? 'inline-block': 'none'};
  width: 100%;
  height: 100vh;
  background: black;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  z-index: 1000;
  animation: ${movimiento} .75s linear;
    
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div:nth-child(1) {
      margin-top: 12%;
      margin-left: 80%;
      width: 30px;
      height: 3px;
      position: relative;
      border-radius: 10px;

      > div:before,
        div:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 3px;
        background-color: ${yellowAuricula};
        border-radius: 10px;
      }

      > div:before {
        -webkit-transform: rotate(50deg);
        -moz-transform: rotate(50deg);
        transform: rotate(50deg);
        transform: rotate(50deg); 
      } 
      
      > div:after {
        -webkit-transform: rotate(-50deg);
        -moz-transform: rotate(-50deg);
        transform: rotate(-50deg);
        right: .8px;
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction:column;
      align-items: center;
      margin-top:30%;
  
      > div:nth-child(1),
        div:nth-child(2),
        div:nth-child(3),
        div:nth-child(4),
        div:nth-child(5) {
        padding-bottom:20% ;
        
        > a {
          text-decoration: none;
          font-size: 6vw;
          font-family:'BebasNeue';
          color:white; 
          cursor: pointer; 
          text-transform: uppercase;
          transition: all 0.5s;
          position: relative;
        }

        > a:hover {
          color:${yellowAuricula};
          background-size: 100% 2px;  
        }

        > a:before {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          left: 0;
          transition: all 0.5s;
        }

        > a:hover:before {
          width: 50%;
          transform: translateX(100%);
        }

        > a:after {
          content: '';
          width: 0;
          height: 2px;
          background-color: ${yellowAuricula};
          position: absolute;
          top: 120%;
          right: 0;
          transition: all 0.5s;
        }

        > a:hover:after {
          width: 50%;
          transform: translateX(-100%);
        }  
      } 
    }
  }
}

`

export const HomePrincipal = styled.section `
  display: ${props => props.showHomePrincipal ? 'flex': 'none'};
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  background:black  ;
  overflow: hidden;

  > div {
    width: 100vw;
    height: 100vh;
    animation: ${movimiento} 1.2s linear;
  }

  @media (min-width:600px) and (max-width: 1024px) {
     
  }

  @media (max-width: 600px) {

  }
`

export const Nav = styled.section `
  display: flex;
  
  @media (min-width:600px) and (max-width: 1024px) {
     
  }

  @media (max-width: 600px) {
    
  }
`


export const NavLogo = styled.section `
  margin : 5% 0 0 5% ;
  width: 40px;
  height: 130px;

  img {
    width: 100%;
    height: 100%;
  }

  @media (min-width:600px) and (max-width: 1024px) {
    
    img {
      
    }
  }

  @media (max-width: 600px) {
    margin : 10% 0 0 10% ;
    
    img {
      
    }
  }
`

export const Navbuttons = styled.section `
  display: flex;
  margin: 5% 0% 0% 60%;

    
  > div {
    padding-right: 15%;

    > a {
      font-size: 1.5vw;
      font-family: 'BebasNeue';
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      transition: all 0.5s;
      position: relative;
      
    }

    > a:hover {
      color:${yellowAuricula};
      background-size: 100% 2px;
    }

    > a:before {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      left: 0;
      transition: all 0.5s;
    }

    > a:hover:before {
      width: 50%;
      transform: translateX(100%);
    }

    > a:after {
      content: '';
      width: 0;
      height: 2px;
      background-color: ${yellowAuricula};
      position: absolute;
      top: 120%;
      right: 0;
      transition: all 0.5s;
    }

    > a:hover:after {
      width: 50%;
      transform: translateX(-100%);
    }  
  }
  
  @media (min-width:600px) and (max-width: 1024px) {
    margin: 5% 0% 0% 50%;

      
    div{


      > a {
       font-size: 2vw;
      }

      > a:hover {
       
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
       
      }

      > a:hover:after {
        
      }
      
    }
  }

  @media (max-width: 600px) {
    display: none;
    margin: 5% 0% 0% 10%;

    div{
      padding-right:15%;

      > a {
        font-size: 3vw;  
      }

      > a:hover {
        
      }

      > a:before {
        
      }

      > a:hover:before {
        
      }

      >  a:after {
        
      }

      > a:hover:after {
       
      }  
    }
  }

`

export const MenuHamburguer = styled.div `
  display: none;
  
  &:hover{
    > div:nth-child(1) {
      
    }
  }

  &:hover{
    > div:nth-child(2) {
    
    }
  }

  &:hover{
    > div:nth-child(3) {

    }
  }

  > div {

  }

  @media (min-width:600px) and (max-width: 1024px) {
    display: none;
  
    &:hover{
      > div:nth-child(1) {
        
      }
    }

    &:hover{
      > div:nth-child(2) {
        
      }
    }

    &:hover{
      > div:nth-child(3) {
        
      }
    }

    > div:nth-child(1) {
     
    }

    > div:nth-child(2) {
     
    }

    > div:nth-child(3) {
      
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin-top: 11%;
    padding-left: 62.5%;
    cursor: pointer;
  
    &:hover{
      > div:nth-child(1) {
        background-color:${yellowAuricula} ;
      }
    }

    &:hover{
      > div:nth-child(2) {
        background-color:${yellowAuricula} ;
        width: 60%;
        transition: all 0.2s linear;
      }
    }

    &:hover{
      > div:nth-child(3) {
        background-color:${yellowAuricula} ;
        width: 20%;
        transition: all 0.2s linear;
      }
    }

    > div {
      margin-bottom: 15%;
      width: 25px;
      height: 2px;
      background-color: white;
    }
  }
`

export const Principal = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top:5%;
  padding-bottom:5.7%;
  

  @media (min-width:600px) and (max-width: 1024px) {
   
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top:5%;
    padding-bottom:5.7%;
  }
 
`

export const WebPageInfo = styled.div `
  display: flex;
  justify-content: center;
  width: 38%;
  margin: auto;
  margin-bottom: 3%;
  cursor: pointer;

  &:hover {

    /* hover del cuadrado */
    > div:nth-child(1) > div {
      background-color: transparent;
    }

    /* hover del texto */
    > div:nth-child(2) > div:nth-child(1) > p {
    color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(1) > div {
      background-color: ${yellowAuricula};
      width: 200px;
    }

      /* hover del texto */
    > div:nth-child(2) > div:nth-child(2) > p {
      color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(2) > div {
      background-color: ${yellowAuricula};
      width: 130px;
    }
}

  > div:nth-child(1) {
      > div {
      width: 25px;
      height: 25px;
      margin-top: 120%;
      background-color: ${yellowAuricula};
      transition: all 0.5s linear;
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    width: 40%;
    margin-bottom: 8%;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {
      
      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
      
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        width: 150px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        width: 100px;
      }
  }

    > div:nth-child(1) {
        > div {
        width: 20px;
        height:20px;
        margin-top: 110%;
      } 
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top:10% ;
    margin-bottom:10%;
    cursor: pointer;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {
        background-color: transparent;
      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
      color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        background-color: ${yellowAuricula};
        width: 200px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        background-color: ${yellowAuricula};
        width: 130px;
      }
    }

    > div:nth-child(1) {
        > div {
        width: 25px;
        height: 25px;
        margin-top: 160%;
        margin-left:40%;
        background-color: ${yellowAuricula};
        transition: all 0.5s linear;
      } 
    }  
  }

`

export const WebPageButton = styled.div `
  display: flex;
  flex-direction: column;
  color: white;
  width: 50%;

  > div:nth-child(1) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      width: 1px;
      height: 45px;
      background-color:${yellowAuricula};
      transition: all 0.5s linear;
    }

    > p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    }    
  }

  > div:nth-child(2) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      margin-left: 0%;
      width: 1px;
      height: 40px;
      background-color: ${yellowAuricula}; 
      transition: all 0.5s linear;
    }

    >p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {
      

      > div {
        
      }

      > p {
        
      }    
    }

    > div:nth-child(2) {
     

      > div {
       
        height: 30px;
      }

      >p {
       
      } 
    }
  }

  @media (max-width: 600px) {
    width: 85%;
  

    > div:nth-child(1) {
      padding-left: 10%;

      > div {
        margin-top: 1%;
        width: 1px;
        height: 56px;
      }

      > p {
        font-size: 12vw;
        font-family:'BebasNeue' ;
      }    
    }

    > div:nth-child(2) {
      padding-left: 10%;
      width: 100%;
      cursor: pointer;

      > div {
        width: 1px;
        height: 50px;
      }

      >p {
        font-size: 12vw;
      } 
    }
  }
`

export const WebPageInfo1 = styled.div `
  display: flex;
  justify-content: center;
  width: 38%;
  margin: auto;
  margin-bottom: 3%;
  cursor: pointer;

  &:hover {

    /* hover del cuadrado */
    > div:nth-child(1) > div {
      background-color: transparent;
    }

    /* hover del texto */
    > div:nth-child(2) > div:nth-child(1) > p {
    color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(1) > div {
      background-color: ${pinkAuricula};
      width: 180px;
    }

      /* hover del texto */
    > div:nth-child(2) > div:nth-child(2) > p {
      color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(2) > div {
      background-color: ${pinkAuricula};
      width: 230px;
    }
}

  > div:nth-child(1) {
  
      > div {
      width: 25px;
      height: 25px;
      margin-top: 120%;
      background-color: ${pinkAuricula};
      transition: all 0.5s linear;
    }
    
  }

  @media (min-width:600px) and (max-width: 1024px) {
    width: 40%;
    margin-bottom: 8%;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {

      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
      
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        width: 140px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        width: 170px;
      }
    }

    > div:nth-child(1) {
        
      > div {
        width: 20px;
        height: 20px;
        margin-top: 110%;
        background-color: ${pinkAuricula};
        transition: all 0.5s linear;
      }    
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top:5%;
    margin-bottom: 0;
    cursor: pointer;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {
        background-color: transparent;
      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
      color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        background-color: ${pinkAuricula};
        width: 180px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        background-color: ${pinkAuricula};
        width: 230px;
      }
    }

    > div:nth-child(1) {
    
        > div {
        width: 25px;
        height: 25px;
        margin-top: 160%;
        margin-left:40%;
        background-color: ${pinkAuricula};
        transition: all 0.5s linear;
      } 
    }
  }
   
`

export const WebPageButton1 = styled.div `
  display: flex;
  flex-direction: column;
  color: white;
  width: 50%;

  > div:nth-child(1) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      width: 1px;
      height: 45px;
      background-color:${pinkAuricula};
      transition: all 0.5s linear;
    }

    > p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    }    
  }

  > div:nth-child(2) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      margin-left: 0%;
      width: 1px;
      height: 40px;
      background-color: ${pinkAuricula}; 
      transition: all 0.5s linear;
    }

    >p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {
     

      > div {
        
      }

      > p {
       
      }    
    }

    > div:nth-child(2) {
     

      > div {
        height: 32px;
      }

      >p {
       
      } 
    }
  }

  @media (max-width: 600px) {
    width: 85%;

    > div:nth-child(1) {
      padding-left: 10%;

      > div {
        margin-top: 1%;
        width: 1px;
        height: 55px;
      }

      > p {
        font-size: 12vw;
        font-family:'BebasNeue' ;
      }    
    }

    > div:nth-child(2) {
      padding-left: 10%;
      width: 100%;
      cursor: pointer;

      > div {
        width: 1px;
        height: 48px;
      }

      >p {
        font-size: 12vw;
      } 
    }
  }
`

export const WebPageInfo2 = styled.div `
  display: flex;
  justify-content: center;
  width: 38%;
  margin: auto;
  cursor: pointer;

  &:hover {

    /* hover del cuadrado */
    > div:nth-child(1) > div {
      background-color: transparent;
    }

    /* hover del texto */
    > div:nth-child(2) > div:nth-child(1) > p {
      color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(1) > div {
      background-color: ${greyAuricula};
      width:160px;
    }

      /* hover del texto */
    > div:nth-child(2) > div:nth-child(2) > p {
      color: black;
    }

    /* hover de la linea que crece */
    > div:nth-child(2) > div:nth-child(2) > div {
      background-color: ${greyAuricula};
      width:130px;
    }
}

  > div:nth-child(1) {

      > div {
      width: 25px;
      height: 25px;
      margin-top: 120%;
      background-color: ${greyAuricula};
      transition: all 0.5s linear;
    }    
  }

  @media (min-width:600px) and (max-width: 1024px) {
    width: 40%;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {
      
      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
      
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        width:120px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        width: 100px;
      }
  }

    > div:nth-child(1) {
        > div {
        width: 20px;
        height: 20px;
        margin-top: 110%;
      }
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top:15%;
    cursor: pointer;

    &:hover {

      /* hover del cuadrado */
      > div:nth-child(1) > div {
        background-color: transparent;
      }

      /* hover del texto */
      > div:nth-child(2) > div:nth-child(1) > p {
        color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(1) > div {
        background-color: ${greyAuricula};
        width:160px;
      }

        /* hover del texto */
      > div:nth-child(2) > div:nth-child(2) > p {
        color: black;
      }

      /* hover de la linea que crece */
      > div:nth-child(2) > div:nth-child(2) > div {
        background-color: ${greyAuricula};
        width:130px;
      }
  }

    > div:nth-child(1) {

        > div {
        width: 25px;
        height: 25px;
        margin-top: 160%;
        margin-left:40%;
        background-color: ${greyAuricula};
        transition: all 0.5s linear;
      }    
    }
  }
    
`

export const WebPageButton2 = styled.div `
  display: flex;
  flex-direction: column;
  color: white;
  width: 50%;

  > div:nth-child(1) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      width: 1px;
      height: 45px;
      background-color:${greyAuricula};
      transition: all 0.5s linear;
    }

    > p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    }    
  }

  > div:nth-child(2) {
    padding-left: 10.5%;
    width: 100%;
    cursor: pointer;

    > div {
      position: absolute;
      margin-left: 0%;
      width: 1px;
      height: 40px;
      background-color: ${greyAuricula}; 
      transition: all 0.5s linear;
    }

    >p {
      position: relative;
      width: 100%;
      font-size: 2.5vw;
      font-family:'BebasNeue' ;
      color: white;
      padding: 0 20px;
      transition: all 0.5s linear;
    } 
  }

  @media (min-width:600px) and (max-width: 1024px) {
    

    > div:nth-child(1) {
      

      > div {
        
      }

      > p {
       
      }    
    }

    > div:nth-child(2) {
      

      > div {
        height: 30px;
      }

      >p {
        
      } 
    }
  }

  @media (max-width: 600px) {
    width: 85%;

    > div:nth-child(1) {
      padding-left: 10%;

      > div {
        margin-top: 1%;
        width: 1px;
        height: 56px;
      }

      > p {
        font-size: 12vw;
        font-family:'BebasNeue' ;
      }    
    }

    > div:nth-child(2) {
      padding-left: 10%;
      width: 100%;
      cursor: pointer;

      > div {
        width: 1px;
        height: 50px;
      }

      >p {
        font-size: 12vw;
      } 
    }
  }
`

export const Main = styled.div `
  display: ${props => props.hideMain ? 'flex' : 'none'};
  align-items: center;
  background-image: url("https://s3.amazonaws.com/atrium1.0/intro.jpg") ;
  height: 100vh;
  background-size: 97% 100%;


  > figure {
    width: 70px;
    height: 250px;
    margin-left: 45%;
    
    > img {
      width: 100%;
      height: 100%;
    }
  }

  > button {
    background: none;
    border:none;
    font-family: 'BebasNeue';
    font-size: 5vw;
    color: white;
    margin-left: 2.5%;
    padding:.5% 1% 0 1%;
    transition: all 0.5s linear;
    cursor: pointer;
    

    &:hover {
      background-color:${yellowAuricula} ;
      color: black;
    }
  }

  @media (min-width:600px) and (max-width: 1024px) {
    background-size: 100% 100%;

    > figure {
      
      > img {
        
      }
    }

    > button {
      font-size: 8vw;

      &:hover {
       
      }
    }
  }

  @media (max-width: 600px) {
    background-image: url("https://s3.amazonaws.com/atrium1.0/Nuevointro_mobile.jpg") ;
    background-size: 90% 100%;
    

    > figure {
      width: 50px;
      height: 150px;
      margin:10% 0 0 43%;
      
      > img {
        
      }
    }

    > button {
      font-size: 15vw;
      margin-left: 8%;
      padding:0% 1% 0 1%;
      

      &:hover {
        height: 9%;
        background-color:${yellowAuricula} ;
        color: black;
      }
    }
  }
`



