import {keyframes} from 'styled-components'

export const movimiento = keyframes `

  0% {
    position: absolute;
    top: -200px;
    left: 0;
  }

  100% {
    position: absolute;
    top: 0;
    left: 0;
  }

`