import React, {useState}  from 'react'
  
import {
  HomePrincipal,
  Nav,
  NavLogo,
  Navbuttons,
  Principal,
  WebPageInfo,
  WebPageButton,
  WebPageInfo1,
  WebPageButton1,
  WebPageInfo2,
  WebPageButton2,
  MenuHamburguer,
  ModalHamburguer
  
} from './services.styles'

import WhatsApp from '../layout/whatsapp/whatsapp'

export default function Services (props) {
  const [ modalHamburguer, setModalHamburguer ] = useState(false)

  const sendToWebPageProgramming = () => {
    props.history.push('/paginas-web-y-programacion')
  }

  const sendToInternalCommunication = () => {
    props.history.push('/comunicacion-interna')
  }

  const sendToDesingAndAnimation = () => {
    props.history.push('/diseño-y-animacion')
  }

  const showModalHamburguer = () => {
    setModalHamburguer(true)
  }

  const closeModalHamburguer = () => {
    setModalHamburguer(false)
  }


  return (
    <>
      <ModalHamburguer showModalHamburguer={modalHamburguer}>
        <div>
          <div>
            <div onClick={closeModalHamburguer}></div>
          </div>
          <div>
            <div>
              <a href="/">HOME</a>
            </div>
            <div>
              <a href="/servicios">SERVICIOS</a>
            </div>
            <div>
              <a href="/reel">REEL</a>
            </div>
            <div>
              <a href="http://www.blog.auricula.mx">BLOG</a> 
            </div>
            <div>
              <a href="/contactanos">CONTACTO</a>
            </div>
          </div>
        </div>
      </ModalHamburguer>
      <WhatsApp/>
      <HomePrincipal>
        <div>
          <Nav>
            <NavLogo >
              <a href="/">
                  <img src="https://s3.amazonaws.com/atrium1.0/logotipo_auricula_amarillo.png" alt="Logotipo de Auricula" />
              </a>
            </NavLogo>
            <Navbuttons>
              <div>
                <a href="/">HOME</a>
              </div>
              <div >
                <a href="/servicios">SERVICIOS</a>
              </div>
              <div>
                <a href="/reel">REEL</a>
              </div>
              <div>
                <a href="http://www.blog.auricula.mx">BLOG</a> 
              </div>
              <div>
                <a href="/contactanos">CONTACTO</a>
              </div>
            </Navbuttons>
            <MenuHamburguer onClick={showModalHamburguer}>
              <div></div>
              <div></div>
              <div></div>
            </MenuHamburguer>
          </Nav>
          <Principal>
            <WebPageInfo onClick={sendToInternalCommunication}>
              <div>
                <div></div>
              </div>
              <WebPageButton>
                <div>
                  <div></div>
                  <p>COMUNICACIÓN</p>
                </div>
                <div>
                  <div></div>
                  <p>INTERNA</p>
                </div>
              </WebPageButton>
            </WebPageInfo>
            <WebPageInfo1 onClick={sendToWebPageProgramming}>
              <div>
                <div></div>
              </div>
              <WebPageButton1>
                <div>
                  <div></div>
                  <p>PÁGINAS WEB</p>
                </div>
                <div>
                  <div></div>
                  <p>& PROGRAMACIÓN</p>
                </div>
              </WebPageButton1>
            </WebPageInfo1>
            <WebPageInfo2>
              <div>
                <div></div>
              </div>
              <WebPageButton2 onClick={sendToDesingAndAnimation}>
                <div>
                  <div></div>
                  <p>ANIMACIÓN</p>
                </div>
                <div>
                  <div></div>
                  <p>& DISEÑO</p>
                </div>
              </WebPageButton2>
            </WebPageInfo2>
          </Principal>
        </div>
      </HomePrincipal>
    </>
  )
}